import { useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';

export default function LoginModal() {
    const { isLoggedIn, login } = useAuth();
    const { isLoginModalOpen, closeLoginModal, openLoginModal, loginReferrer, openCouponModal, openFeedbackModal } = useModal();
    const navigate = useNavigate();

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                closeLoginModal();
            }
        };
        
        if (isLoginModalOpen) {
            document.addEventListener('keydown', handleEscape);
        }
        
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isLoginModalOpen, closeLoginModal]);

    const handleLoginSuccess = async (credentialResponse) => {
      const loggedIn = await login(credentialResponse);
      if (loggedIn) {
        closeLoginModal();
        if (loginReferrer && loginReferrer === '/coupons') {
          openCouponModal();

        } else if (loginReferrer && loginReferrer === '/feedback') {
          openFeedbackModal();
        } else if (window.location.pathname === '/') {
          navigate('/create');
        }
      }
    };
    
    const handleLoginError = (error) => {
      console.error('Login Failed:', error);
    };

    useEffect(() => {
        console.log('Modal open state:', isLoginModalOpen);
    }, [isLoginModalOpen]);

    if (!isLoginModalOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto z-99">
            {/* Overlay */}
            <div 
                className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
                onClick={closeLoginModal}
            />
            
            {/* Modal */}
            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-gray-800 rounded-lg p-12 max-w-md w-full shadow-xl">
                    {/* Close button */}
                    <button
                        onClick={closeLoginModal}
                        className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
                        aria-label="Close modal"
                    >
                        <svg 
                            className="w-6 h-6" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>

                    <h2 className="text-2xl font-bold text-white mb-8">Sign up or Log in</h2>
                    <p className="text-gray-300 mb-8">
                        Choose your preferred sign in method:
                    </p>
                    
                    {/* Google Login - Active */}
                    <div className="mx-auto mb-8">
                        <GoogleLogin
                            onSuccess={handleLoginSuccess}
                            onError={handleLoginError}
                            width="350"
                        />
                    </div>

                    {/* Divider with "Coming Soon" label */}
                    <div className="relative mb-8">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-600"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 text-gray-400 bg-gray-800">Coming Soon</span>
                        </div>
                    </div>

                    {/* Disabled login options */}
                    <div className="space-y-4 mb-8">
                        {/* Facebook */}
                        <button 
                            disabled
                            className="w-full flex items-center justify-center gap-3 bg-[#1877F2] bg-opacity-50 text-white rounded-lg px-4 py-2 cursor-not-allowed"
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12c0 5.989 4.388 10.952 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 22.952 24 17.989 24 12c0-6.627-5.373-12-12-12z"/></svg>
                            Facebook Login
                            <span className="text-sm opacity-75">(Coming Soon)</span>
                        </button>

                        {/* Apple */}
                        <button 
                            disabled
                            className="w-full flex items-center justify-center gap-3 bg-black bg-opacity-50 text-white rounded-lg px-4 py-2 cursor-not-allowed"
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24"><path d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701"/></svg>
                            Sign in with Apple
                            <span className="text-sm opacity-75">(Coming Soon)</span>
                        </button>

                        {/* LinkedIn */}
                        <button 
                            disabled
                            className="w-full flex items-center justify-center gap-3 bg-[#0A66C2] bg-opacity-50 text-white rounded-lg px-4 py-2 cursor-not-allowed"
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24"><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                            LinkedIn Sign-in
                            <span className="text-sm opacity-75">(Coming Soon)</span>
                        </button>

                        {/* Username/Password */}
                        <button 
                            disabled
                            className="w-full flex items-center justify-center gap-3 bg-gray-600 bg-opacity-50 text-white rounded-lg px-4 py-2 cursor-not-allowed"
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/></svg>
                            Username & Password
                            <span className="text-sm opacity-75">(Coming Soon)</span>
                        </button>
                    </div>

                    <p className="text-gray-300 text-sm text-center">
                        Additional login methods are currently in development and will be available soon!
                    </p>
                </div>
            </div>
        </div>
    );
} 