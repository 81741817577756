// src/pages/HomePage.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import SubHeader from '../components/global/SubHeader.js';
import MainContent from '../components/homeContent/MainContent.js';
import VideoSection from '../components/homeContent/VideoSection.js';
import PreviewSection from '../components/homeContent/PreviewSection.js';
import Testimonials from '../components/homeContent/Testimonials.js';
import Features from '../components/homeContent/Features.js';
import Pricing from '../components/purchase/Pricing.js';
import FAQSection from '../components/homeContent/FAQSection.js';
import Footer from '../components/global/Footer.js';



export default function HomePage() {
  const navigate = useNavigate();
  const [isFadedIn, setIsFadedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);

  // Detect if the device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Handle fade-in effect for the entire page
  useEffect(() => {
    setIsFadedIn(false);
    const fadeTimeout = setTimeout(() => {
      setIsFadedIn(true);
    }, 100);

    return () => clearTimeout(fadeTimeout);
  }, []);

  return (

    <div className="min-h-screen bg-gray-950 flex flex-col">
      <SubHeader />

      <MainContent />

      <VideoSection isFadedIn={isFadedIn} isMobile={isMobile} />

      <Testimonials />

      <Features />

      <PreviewSection />

      <Pricing />

      <FAQSection />
    </div>
  );
}
