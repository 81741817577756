import axios from 'axios';
import { getApiUrl } from './config';
const emitTrackEvent = async (eventKey, eventData) => {
  try {
    await axios.post(`${getApiUrl()}/track`, 
      {
        eventKey,
        ...eventData
      }, 
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  } catch (error) {
    console.error('Failed to track event', error);
  }

  window.gtag('event', eventKey, {
    'event_category': 'User Interaction',
    ...eventData
  });
};

export { emitTrackEvent };