// ../components/InPaintModal.js
import React from 'react';
import Modal from '../../modals/Modal';
import { FaTimes } from 'react-icons/fa';
import ImageSlider from './ImageSlider';
import ImageCanvas from './ImageCanvas';
import PromptSection from './PromptSection';

const InPaintModal = ({
  onClose,
  modifiedImageUrl,
  imageUrl,
  canvasRef,
  selection,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  prompt,
  setPrompt,
  performInpainting,
  isLoading,
  error,
}) => {
  return (
    <Modal onClose={onClose}>
      <button onClick={onClose} className="absolute top-3 right-3 text-gray-600 hover:text-gray-800">
        <FaTimes size={20} />
      </button>
      <div className="w-auto h-auto flex justify-center">
        {/* Image container */}
        <div className="flex-1 flex justify-center items-center overflow-auto rounded-md max-h-full mr-2 relative">
          {modifiedImageUrl && (
            <ImageSlider modifiedImageUrl={modifiedImageUrl} />
          )}
          <ImageCanvas
            imageUrl={imageUrl}
            canvasRef={canvasRef}
            selection={selection}
            handleMouseDown={handleMouseDown}
            handleMouseMove={handleMouseMove}
            handleMouseUp={handleMouseUp}
          />
        </div>
        {/* Input and button container */}
        <PromptSection
          prompt={prompt}
          setPrompt={setPrompt}
          performInpainting={performInpainting}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </Modal>
  );
};

export default InPaintModal;