import React, { createContext, useContext, useState, useEffect } from 'react';
import { logIn, logOut, getSession, setSessionCreditBalance, getSessionCreditBalance } from '../utils/session';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [creditBalance, setCreditBalance] = useState(0);

  useEffect(() => {
    // Initialize auth state from session
    const session = getSession();
    setCurrentUser(session.currentUser || null);
    setAccount(session.account || null);
    const sessionCreditBalance = getSessionCreditBalance();
    setCreditBalance(sessionCreditBalance);
    setLoading(false);
  }, []);

  const login = async (googleResponse) => {
    try {
      const success = await logIn(googleResponse);

      if (success) {
        const session = getSession();
        setCurrentUser(session.currentUser);
        setAccount(session.account);
        setCreditBalance(session.account?.creditBalance || 0);
      }
      return success;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await logOut();
      await setCurrentUser(null);
      await setAccount(null);
      await setCreditBalance(0);
      return true;
    } catch (error) {
      console.error('Logout failed:', error);
      return false;
    }
  };

  const value = {
    currentUser,
    account,
    creditBalance,
    setCreditBalance,
    login,
    logout,
    isLoggedIn: !!currentUser,
    setLoading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
