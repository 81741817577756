import React, { useEffect, useState } from 'react';

const FactCarousel = ({ facts, duration = 5000 }) => {
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const fadeTimeout = setTimeout(() => {
      setFadeIn(true);
    }, 2000);

    return () => clearTimeout(fadeTimeout);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentFactIndex(prevIndex => (prevIndex + 1) % facts.length);
    }, duration); 

    return () => clearInterval(intervalId); 
  }, [facts.length, duration]);

  return (
    <div className={`mt-10 ${fadeIn ? 'animate-fade-in' : 'opacity-0'}`}>
      <h2 className='font-bold'>Did you know?</h2>
      <ol className="mt-2 mx-3">
        <li className="text-xl transition-opacity duration-500 ease-in-out opacity-100 px-5">
          {facts[currentFactIndex]}
        </li>
      </ol>
    </div>
  );
};

export default FactCarousel;
