// src/components/PreviewSection.js
import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../shared/CustomButton';
import { FaArrowRight } from 'react-icons/fa';

export default function PreviewSection() {
  const [isSectionInView, setIsSectionInView] = useState(false);
  const sectionRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSectionInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);

  // Tilt effect
  useEffect(() => {
    const el = videoRef.current;

    if (!el) return;

    const height = el.clientHeight;
    const width = el.clientWidth;

    const handleMove = (e) => {
      const rect = el.getBoundingClientRect();
      const xVal = e.clientX - rect.left;
      const yVal = e.clientY - rect.top;

      const yRotation = 8 * ((xVal - width / 2) / width);
      const xRotation = -8 * ((yVal - height / 2) / height);

      el.style.transform = `perspective(1000px) scale(1.1) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;
    };

    const handleOut = () => {
      el.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)';
    };

    const handleDown = () => {
      el.style.transform = 'perspective(500px) scale(0.9) rotateX(0) rotateY(0)';
    };

    const handleUp = () => {
      el.style.transform = 'perspective(500px) scale(1.1) rotateX(0) rotateY(0)';
    };

    el.addEventListener('mousemove', handleMove);
    el.addEventListener('mouseout', handleOut);
    el.addEventListener('mousedown', handleDown);
    el.addEventListener('mouseup', handleUp);

    return () => {
      el.removeEventListener('mousemove', handleMove);
      el.removeEventListener('mouseout', handleOut);
      el.removeEventListener('mousedown', handleDown);
      el.removeEventListener('mouseup', handleUp);
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="w-full bg-pink-400 text-black flex flex-col items-center justify-center pt-20 overflow-hidden"
    >
      <h2
        className={`text-3xl text-center md:text-7xl font-bold mb-4 transition-all duration-1000 delay-500 leading-tight text-white md:mt-10 ${
          isSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        Your Ads on Every Platform
      </h2>
      <div
        className={`text-lg leading-tight md:leading-normal md:text-2xl transition-all duration-1000 delay-700 w-[90%] md:w-[80%] md:max-w-[70%] text-center ${
          isSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <p>
        See exactly how your ads look on TikTok, Instagram, and more. Fine-tune the details and download your campaign-ready visuals with our easy-to-use simulator.
        </p>

        <CustomButton className="text-lg p-3 md:text-2xl md:px-5 md:py-3 mx-auto mt-5 mb-10 bg-black border-none hover:bg-white hover:text-black inline text-white">
          Start Free Now <FaArrowRight className="ml-4" />
        </CustomButton>
      </div>

      <div
        className={`w-full scale-[120%] md:scale-100 mt-2 max-w-5xl px-4 transition-all duration-1000 delay-900 md:mt-10 ${
          isSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <div
          ref={videoRef}
          className="w-full md:w-full max-w-5xl px-4 transition-all duration-1000 delay-900"
          style={{ transition: 'box-shadow 0.1s, transform 0.1s' }}
        >
          <video
            src="/videos/iforads.mp4"
            className="w-full h-auto rounded-tl-lg rounded-tr-lg shadow-md"
            muted
            loop
            playsInline
            autoPlay
          />
        </div>
      </div>
    </section>
  );
}