import React, { useState, useEffect } from 'react';
import { useModal } from '../../contexts/ModalContext';
import { useAuth } from '../../contexts/AuthContext';
import { getApiUrl } from '../../utils/config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setSessionCreditBalance } from '../../utils/session';
import { toast } from 'react-toastify';

export default function CouponModal() {
  const { closeCouponModal, isCouponModalOpen, openLoginModal } = useModal();
  const { isLoggedIn, currentUser, account } = useAuth();
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const { setCreditBalance } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isCouponModalOpen && !isLoggedIn) {
      closeCouponModal();
      openLoginModal('/coupons');
    }
  }, [isCouponModalOpen, isLoggedIn]);

  const onCloseCouponModal = () => {
    setErrorMessage(null);
    setCouponCode('');
    closeCouponModal();
  };

  const submitCouponCode = async (e) => {
    e.preventDefault();
    setErrorMessage(null);

    try {
      const response = await axios.post(`${getApiUrl()}/payments/redeem`, {
        couponCode,
        userId: currentUser._id,
        accountId: account._id,
      });

      if (response.status === 403) {
        closeCouponModal();
        localStorage.setItem('showSessionAlert', true);
        navigate("/logout");
        return;
      }

      if (response.status === 200) {
        const newCreditBalance = response.data.newCreditBalance;
        const codeCreditValue = response.data.codeCreditValue;

        setSessionCreditBalance(newCreditBalance);
        setCreditBalance(newCreditBalance);
        toast.info(`Rad! We've added ${codeCreditValue} credits to your account! Your new balance is ${newCreditBalance}.`);
        closeCouponModal();
        return;
      }

      setErrorMessage(response.data.error);

    } catch (error) {
      setErrorMessage(error);
    }
  };

  return (
    isCouponModalOpen && (
      <div className="fixed inset-0 z-50">
        {/* Overlay */}
        <div 
          className="absolute inset-0 bg-black/50 backdrop-blur-sm"
          onClick={onCloseCouponModal}
        />
        
        {/* Modal */}
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="bg-white p-6 rounded-lg max-w-md w-full relative">
            <button
              onClick={onCloseCouponModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              aria-label="Close modal"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <h2 className="text-3xl font-bold text-center mb-6 mt-4 text-gray-900">
              Enter Your Coupon Code!
            </h2>
            {errorMessage && <p className="text-red-500 font-semibold text-center mb-4">{errorMessage}</p>}
            <form onSubmit={submitCouponCode} className="space-y-4">
              <input
                type="text"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                placeholder="Enter code here..."
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
              />
              
              <button
                type="submit"
                className="w-full px-6 py-3 text-xl font-normal rounded-md bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white hover:from-fuchsia-700 hover:to-pink-700 transition-all duration-300"
              >
                Claim My Credits
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
