// src/components/FAQSection.js

/**
 * FAQSection Component
 * 
 * @component
 * @description Displays a list of frequently asked questions and answers about the ImagesForAds.Ai service.
 *              The content is static to improve SEO and provide a better user experience.
 * 
 * @example
 *  <FAQSection />
 * 
 * @returns {JSX.Element} A section with a title, an introduction, and a list of FAQs.
 */

import React from 'react';

export default function FAQSection() {
    return (
        <section className="bg-gray-100 pb-20 pt-0">
            <div className="max-w-6xl mx-auto px-4 md:mt-10 md:grid md:grid-cols-2 md:gap-8">
                {/* Left Column: Heading and Intro Text */}
                <div className="mb-12 md:mb-0 md:max-w-[80%]">
                    <h2 className="text-3xl md:text-5xl font-bold mb-4">
                        Frequently Asked Questions
                    </h2>
                    <p className="text-xl md:text-2xl">
                        Got more questions? <a href="#!" className="text-pink-600 underline">Drop us a message</a> and we'll get back to you!
                    </p>
                </div>

                {/* Right Column: FAQs */}
                <div className="space-y-8">
                    <div className="transition-all text-gray-700">
                        <h3 className="text-xl leading-6 md:text-2xl font-semibold mb-2">
                            Are the images generated royalty-free?
                        </h3>
                        <p className="text-gray-600 text-lg md:text-xl">
                            Yes! Every image our AI generates is 100% royalty-free. Use them in your projects without worrying about extra costs or licensing fees. It’s that simple!
                        </p>
                    </div>
                    <div className="transition-all text-gray-700">
                        <h3 className="text-xl leading-6 md:text-2xl font-semibold mb-2">
                            Can I cancel any time?
                        </h3>
                        <p className="text-gray-600 text-lg md:text-xl">
                            Absolutely. You’re free to cancel images.fords.ai whenever you want—no long-term contracts, no strings attached.
                        </p>
                    </div>
                    <div className="transition-all text-gray-700">
                        <h3 className="text-xl leading-6 md:text-2xl font-semibold mb-2">
                            What licensing options do I have?
                        </h3>
                        <p className="text-gray-600 text-lg md:text-xl">
                            You get full commercial rights. Use the images in your marketing, ads, social media, or wherever you need them. From startups to big enterprises, we’ve got licensing that fits.
                        </p>
                    </div>
                    <div className="transition-all text-gray-700">
                        <h3 className="text-xl leading-6 md:text-2xl font-semibold mb-2">
                            How do you handle copyright issues?
                        </h3>
                        <p className="text-gray-600 text-lg md:text-xl">
                            Copyright is a big deal, and we get that. Our AI is designed to create unique images, minimizing any risk of infringement. And if anything comes up, our support team is ready to help.
                        </p>
                    </div>
                    <div className="transition-all text-gray-700">
                        <h3 className="text-xl leading-6 md:text-2xl font-semibold mb-2">
                            Where can I use my images?
                        </h3>
                        <p className="text-gray-600 text-lg md:text-xl">
                            Anywhere! Websites, social media, print—you name it. Plus, we leverage insider knowledge from platforms like LinkedIn and Facebook to ensure your campaigns are hassle-free.
                        </p>
                    </div>
                    <div className="transition-all text-gray-700">
                        <h3 className="text-xl leading-6 md:text-2xl font-semibold mb-2">
                            Can I use the service for free?
                        </h3>
                        <p className="text-gray-600 text-lg md:text-xl">
                            Yes! We offer a free tier so you can jump in right away. And when our advanced tools launch, early users get a special discount. It’s our way of saying thanks!
                        </p>
                    </div>
                    <div className="transition-all text-gray-700">
                        <h3 className="text-xl leading-6 md:text-2xl font-semibold mb-2">
                            What payment methods do I have?
                        </h3>
                        <p className="text-gray-600 text-lg md:text-xl">
                            We accept credit cards (Visa, Mastercard, and American Express). Sorry, but we don’t do invoices, checks, wire transfers, PayPal, or cryptocurrency. ACH transfers and digital wallets (Apple Pay, Google Wallet) COMING SOON!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}