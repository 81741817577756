// src/components/MainContent.js
/**
 * MainContent Component
 *
 * @component
 * @description This component renders the main content of the page, including a typewriter effect, 
 * marketing text, a custom button for trying the service, and a Google Login button for authentication.
 * It handles user login, responsive design adjustments, and a fade-in animation for a smooth user experience.
 * Right now, its being called by HomePage.js
 * 
 * It renders with <MainContent />
 *
 * @returns {JSX.Element} The main content of the page with interactive elements.
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../shared/CustomButton';
import TypewriterEffect from './TypewriterEffect';

export default function MainContent() {
  const navigate = useNavigate();
  const [isFadedIn, setIsFadedIn] = useState(false);
  const [buttonWidth, setButtonWidth] = useState('auto');
  const [isMobile, setIsMobile] = useState(false);

  // Update button width based on window size
  useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth <= 768) {
        setButtonWidth(280);
      } else {
        setButtonWidth('auto');
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  // Detect if the device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle fade-in effect
  useEffect(() => {
    setIsFadedIn(false);
    const fadeTimeout = setTimeout(() => {
      setIsFadedIn(true);
    }, 100);

    return () => clearTimeout(fadeTimeout);
  }, []);

  return (
    <main className="flex-1 flex flex-col items-center justify-center px-6">
      <TypewriterEffect
        className={`transform transition-all duration-1000 min-h-[165px] md:min-h-[180px] lg:min-h-[300px] ${
          isFadedIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        }`}
      />
      <p
        className={`transform transition-all duration-1000 text-2xl text-gray-400 delay-500 mb-8 ${
          isFadedIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        }`}
      >
        Boost Social Media Engagement with{" "}
        <b className="font-bold text-white">AI Images</b> Optimized for{" "}
        <b className="font-bold text-white">Marketing Success</b>
      </p>

      <div
        className={`transform transition-all duration-1000 delay-1000 text-center ${
          isFadedIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        }`}
      >
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <CustomButton className="px-8 py-3 inline text-2xl text-white" onClick={() => navigate('/create')}>
            Try Now For Free
          </CustomButton>
        </div>
        <p className="mt-1 text-gray-400">No credit card required</p>
      </div>
    </main>
  );
}