// src/components/VideoSection.js
import React, { useEffect, useRef, useState } from 'react';

export default function VideoSection({ isFadedIn, isMobile }) {
  const [videoIndex, setVideoIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false); // Nuevo estado para el efecto de fade
  const videoRef = useRef(null);
  const videoElementRef = useRef(null);
  const [isVideoInView, setIsVideoInView] = useState(false);

  const videos = ['/videos/fitness.mp4', '/videos/makeup.mp4'];
  const images = ['/images/makeup.png', '/images/fitness.png'];

  const handleVideoEnd = () => {
    setIsFadingOut(true); // Inicia el fade out

    setTimeout(() => {
      
      setVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
      setShowImage(true);
      setTimeout(() => {
        setIsFadingOut(false); 
      }, 1400);
    }, 1000); // 1000 ms para el efecto de fade out
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVideoInView(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  useEffect(() => {
    if (videoElementRef.current) {
      if (isVideoInView) {
        videoElementRef.current.currentTime = 0;
        videoElementRef.current.play();
      } else {
        videoElementRef.current.pause();
      }
    }
  }, [isVideoInView]);

  return (
    <section
      className={`transform transition-all duration-1000 delay-[1500ms] xl:mt-[40px] 2xl:max-w-[1800px] mx-auto ${
        isFadedIn ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div className="relative md:w-[95%] xl:w-[90%] 2xl:w-[78%] mx-auto hover:scale-105 transition-all duration-700">
        {/* Video Overlay */}
        <div
          ref={videoRef}
          className={`absolute z-20 left-1/2 transform -translate-x-1/2 md:transform-none md:left-auto md:-right-[1%] mt-0 xl:right-[35%] 2xl:right-[40%] md:top-24 xl:top-5 2xl:-top-3 shadow-lg shadow-black w-[90%] md:w-48 h-auto xl:w-64 md:h-64 lg:w-72 lg:h-auto transition-opacity duration-300 ${
            isFadingOut ? 'opacity-0' : 'opacity-100' 
          }`}
        >
          <video
            src={videos[videoIndex]}
            className="w-full h-full md:object-cover rounded-md shadow-md"
            autoPlay
            muted
            playsInline
            onLoadedMetadata={(e) => (e.currentTarget.playbackRate = 2)}
            onEnded={() => {
              handleVideoEnd();
              setTimeout(() => {
                setShowImage(false);
                setTimeout(() => {
                  setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
                  setShowImage(true);
                }, 500);
              }, 500);
            }}
          />
        </div>

        <div className="relative mx-auto w-[90%] md:w-full border border-gray-800 overflow-hidden mt-10 rounded-md bg-gray-900">
          {/* Background Image */}
          <div
            className={`md:absolute right-0 md:-right-40 xl:right-0 top-0 transform md:-translate-y-1/2 transition-transform duration-500 ease-in-out ${
              showImage
                ? 'xl:translate-x-[10%] 2xl:translate-x-[0%]'
                : 'translate-x-[100%]'
            } ${isMobile ? 'w-full translate-x-0' : 'top-1/2'}`}
          >
            <img
              src={images[imageIndex]}
              alt="Background"
              className={`${
                isMobile ? 'h-auto w-full object-fit' : 'w-[80%] rotate-6 float-right -mr-16'
              }`}
            />
          </div>

          <div
            className={`relative z-10 p-5 md:p-7 lg:p-10 text-white md:max-w-[42%] xl:max-w-[48%] 2xl:max-w-[40%] ${
              isMobile ? 'h-auto flex flex-col justify-center pb-10' : ''
            }`}
          >
            {isMobile ? (
              // Mobile version
              <>
                <h2 className="text-2xl font-bold">One Click to your High-Impact Ads</h2>
                <h3 className="text-lg">Set Your Goal and Go</h3>
                <p className="text-md my-3">
                Set your goal, and our AI will take it from there. Want banners, product shots, or promo graphics? Done in seconds. And it’s not just any images; these are designed with the latest social media trends to boost engagement and drive your ROI.
                </p>
                <ol className="text-md list-disc ml-4 space-y-2">
                  <li>Social Media Ready: For Instagram, LinkedIn, Facebook, and TikTok.</li>
                  <li>Customizable: Match your brand and campaign goals.</li>
                </ol>
              </>
            ) : (
              // Desktop version
              <>
                <h2 className="text-3xl font-bold">One Click to High-Impact Ads</h2>
                <h3 className="text-2xl text-gray-300">Just Set Your Goal and Go</h3>
                <p className="text-lg my-4 text-gray-300">
                Set your goal, and our AI will take it from there. Want banners, product shots, or promo graphics? Done in seconds. And it’s not just any images; these are designed with the latest social media trends to boost engagement and drive your ROI.
                </p>
                <ol className="text-lg list-disc ml-5 space-y-4 text-gray-300">
                  <li><span className='text-white'>Social Media Optimized</span>: Tailored for Instagram, LinkedIn, Facebook, TikTok, and more.</li>
                  <li><span className='text-white'>Fully Customizable:</span> Align visuals with your brand identity and campaign goals.</li>
                </ol>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}