import React from 'react';
import spinner from '../../images/spinner.svg';

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="absolute inset-0 bg-gray-800/50 flex items-center justify-center z-50">
      {/* <div className="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent"></div> */}
      <img src={spinner} alt="Loading spinner" className='mx-auto mb-3' />
    </div>
  );
};

export default LoadingOverlay; 