// ../components/ImageCanvas.js
import React, { useEffect } from 'react';

const ImageCanvas = ({
  imageUrl,
  canvasRef,
  selection,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
}) => {
  useEffect(() => {
    if (imageUrl && canvasRef.current) {
      const img = new Image();
      img.crossOrigin = 'anonymous'; 
      img.src = imageUrl;
      img.onload = () => {
        const ctx = canvasRef.current.getContext('2d');
        canvasRef.current.width = img.width;
        canvasRef.current.height = img.height;
        canvasRef.current.style.width = '100%';
        canvasRef.current.style.height = 'auto';
        ctx.drawImage(img, 0, 0);
      };
    }
  }, [imageUrl, canvasRef]);

  return (
    <canvas
      ref={canvasRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      className="border border-black block max-w-full h-auto z-20"
    />
  );
};

export default ImageCanvas;