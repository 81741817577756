import React from 'react';
import { FaInfinity } from "react-icons/fa6";

const Unlimited = () => {
  return (
    <div className="group relative inline-block">
      <FaInfinity className="mt-[9px] mr-[4px]" />
      <div className="absolute hidden group-hover:block bg-gray-800 text-white text-sm px-2 py-1 rounded -bottom-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap z-10">
        You have unlimited credits!
      </div>
    </div>
  );
};

export default Unlimited;