// ../components/InPaintButton.js

/**
 * InPaintButton Component
 *
 * Main component for the inpainting feature, broken down into clear, modular parts:
 *
 * Structure:
 * 
 * - InPaintButton.js
 *   ├─ Manages state, handles API calls, and opens the modal.
 * 
 * - InPaintModal.js
 *   ├─ Provides the modal layout, using:
 *       ├─ ImageSlider: Displays the modified image.
 *       ├─ ImageCanvas: Manages image display and user selection.
 *       └─ PromptSection: Handles prompt input, instructions, and the "Fine Tune" button.
 * 
 * - ImageCanvas.js
 *   ├─ Handles canvas rendering and user interactions for area selection.
 * 
 * - PromptSection.js
 *   ├─ Manages the prompt input field and "Fine Tune" controls.
 *
 * Usage:
 * 
 * - Import `InPaintButton` to enable image editing and inpainting functionality in your app.
 */

import React, { useState, useEffect, useRef } from 'react';
import { MdAutoFixHigh } from "react-icons/md";
import InPaintModal from './InPaintModal';
import '../../../App.css';
import { getApiUrl } from '../../../utils/config';
const InPaintButton = ({ imageUrl }) => {
  const [selection, setSelection] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [modifiedImageUrl, setModifiedImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const canvasRef = useRef(null);
  const imgRef = useRef(null);

  // Load image when modal is open
  useEffect(() => {
    if (imageUrl && isModalOpen) {
      if (!canvasRef.current) return;
      const img = new Image();
      img.crossOrigin = 'anonymous'; 
      img.src = imageUrl;
      img.onload = () => {
        imgRef.current = img;
        const originalWidth = img.width;
        const originalHeight = img.height;

        setImageDimensions({ width: originalWidth, height: originalHeight });

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = originalWidth;
        canvas.height = originalHeight;

        canvas.style.width = '100%';
        canvas.style.height = 'auto';

        ctx.drawImage(imgRef.current, 0, 0);
      };
    }
  }, [imageUrl, isModalOpen]);

  // Get canvas coordinates
  const getCanvasCoordinates = (e) => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };
    const rect = canvas.getBoundingClientRect();

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;
    return { x, y };
  };

  // Selection handlers
  const handleMouseDown = (e) => {
    const { x, y } = getCanvasCoordinates(e);
    setSelection({ x, y, width: 0, height: 0 });
    setIsSelecting(true);
  };

  const handleMouseMove = (e) => {
    if (!isSelecting) return;
    const { x, y } = getCanvasCoordinates(e);
    setSelection((prev) => ({
      ...prev,
      width: x - prev.x,
      height: y - prev.y,
    }));
    drawSelection();
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
  };

  // Draw selection
  const drawSelection = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (imgRef.current) {
      ctx.drawImage(imgRef.current, 0, 0);
    }

    if (selection) {
      ctx.globalAlpha = 0.5;
      ctx.fillStyle = 'white';
      ctx.fillRect(selection.x, selection.y, selection.width, selection.height);

      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(selection.x, selection.y, selection.width, selection.height);
    }
  };

  // Create mask canvas
  const createMaskCanvas = () => {
    if (!selection) return null;
    const canvas = canvasRef.current;
    if (!canvas) return null;
  
    const maskCanvas = document.createElement('canvas');
    maskCanvas.width = canvas.width;
    maskCanvas.height = canvas.height;
    const ctx = maskCanvas.getContext('2d');
  
    if (imgRef.current) {
      ctx.drawImage(imgRef.current, 0, 0);
    }
  
    ctx.clearRect(selection.x, selection.y, selection.width, selection.height);
  
    return maskCanvas;
  };
  
  // Create transparent blob
  const createTransparentBlob = async () => {
    const canvas = canvasRef.current;
    if (!canvas) return null;
    const ctx = canvas.getContext('2d');
  
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;
    const tempCtx = tempCanvas.getContext('2d');
  
    if (imgRef.current) {
      tempCtx.drawImage(imgRef.current, 0, 0);
    }
  
    if (selection) {
      tempCtx.clearRect(selection.x, selection.y, selection.width, selection.height);
    }
  
    return new Promise((resolve) => {
      tempCanvas.toBlob(resolve, 'image/png');
    });
  };

  // Perform inpainting
  const performInpainting = async () => {
    const maskCanvas = createMaskCanvas();
    if (!maskCanvas) {
      setError('Por favor, selecciona un área de la imagen.');
      return;
    }
  
    if (!prompt.trim()) {
      setError('El prompt no puede estar vacío.');
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      // Create image blob
      const imageBlob = await new Promise((resolve) => {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        tempCanvas.width = imgRef.current.width;
        tempCanvas.height = imgRef.current.height;

        tempCtx.drawImage(imgRef.current, 0, 0);

        tempCanvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            setError('Error al crear el Blob de la imagen.');
            resolve(null);
          }
        }, 'image/png');
      });

      // Create mask blob
      const maskBlob = await new Promise((resolve) => {
        maskCanvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            setError('Error al crear el Blob de la máscara.');
            resolve(null);
          }
        }, 'image/png');
      });

      if (!imageBlob || !maskBlob) {
        setIsLoading(false);
        return;
      }

      // Download blobs (optional)
      const downloadBlob = (blob, filename) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
      };

      // downloadBlob(imageBlob, 'image.png');
      // downloadBlob(maskBlob, 'mask.png');

      // Convert blobs to files
      const imageFile = new File([imageBlob], 'image.png', { type: 'image/png' });
      const maskFile = new File([maskBlob], 'mask.png', { type: 'image/png' });
      
      // Create FormData
       // TODO use multer to send this and calculate the size depending on the user's options
      // for now, we are only using 1024x1024
      // https://github.com/La-Reverie/imagesforads/issues/68
      // 
      const formData = new FormData();
      formData.append('model', 'dall-e-2');
      formData.append('image', imageFile);
      formData.append('mask', maskFile);
      formData.append('prompt', prompt);
      formData.append('n', '1');
      formData.append('size', '1024x1024');

      // We get the authorization token from localstorage
      const token = localStorage.getItem('token');
  
      // API request
      const response = await fetch(`${getApiUrl()}/generate/inpaint`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Incluye el token aquí
        },
        body: formData,
      });
      
      // We get the image back from the api
      const data = await response.json();
  
      if (response.ok) {
        if (data && data.images && data.images[0] && data.images[0].url) {
          const imageUrl = data.images[0].url;
          window.open(imageUrl, '_blank');
          setModifiedImageUrl(imageUrl);
        } else {
          setError('No modified image was received.');
        }
      } else {
        console.error('Error performing inpainting:', data);
        setError('Error performing inpainting: ' + data.error.message);
      }
    } catch (error) {
      console.error('Error performing inpainting:', error);
      setError('An error occurred while processing the image.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Download image function
  const downloadImage = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'modified_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Close modal and reset state
  const closeModal = () => {
    setIsModalOpen(false);
    setError(null);
    setIsLoading(false);
    setPrompt('');
    setModifiedImageUrl(null);
    setSelection(null);
    if (imgRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(imgRef.current, 0, 0);
    }
  };

  return (
    <div>
      {/* Button to open the modal */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="w-auto px-3 bg-transparent border-pink-700 border-2 text-white py-1.5 rounded-md flex items-center justify-center space-x-2 mx-2 hover:bg-pink-500"
      >
        <MdAutoFixHigh className="text-lg" />
        <span>Fix</span>
      </button>
    
      {/* Modal */}
      {isModalOpen && (
        <InPaintModal
          onClose={closeModal}
          modifiedImageUrl={modifiedImageUrl}
          imageUrl={imageUrl}
          canvasRef={canvasRef}
          selection={selection}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          prompt={prompt}
          setPrompt={setPrompt}
          performInpainting={performInpainting}
          isLoading={isLoading}
          error={error}
        />
      )}
    </div>
  );
};

export default InPaintButton;