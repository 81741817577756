import { useState, useContext, useEffect } from 'react';
import AddPayment from './AddPayment';
import Cart from './Cart';
import Checkout from './Checkout';
import Receipt from './Receipt';
import squareLogo from '../../images/built-with-square.png';
import sslSecureBadge from '../../images/ssl-secure-badge.gif';
import { useCheckout } from '../../contexts/CheckoutContext';

export default function Purchase() {
  const { activeStep, setActiveStep, checkoutTotal, setCheckoutTotal } = useCheckout();

  useEffect(() => {
    console.log('CheckoutContext values changed:', { activeStep, checkoutTotal });
  }, [activeStep, checkoutTotal]);

  const steps = [
    {
      title: 'Payment Method',
      content: <AddPayment onContinue={() => setActiveStep(1)} />
    },
    {
      title: 'Cart',
      content: <Cart onContinue={() => setActiveStep(2)} />
    },
    {
      title: 'Checkout',
      content: <Checkout onContinue={() => setActiveStep(3)} />
    },
    {
      title: 'Receipt',
      content: <Receipt />
    }
  ];

  const handleClick = (index) => {
    if (activeStep >= index && activeStep !== 3) {
      setActiveStep(index);
    }
  };

  return (
    <>
    <div className="w-full max-w-3xl mx-auto space-y-2 mt-[30px]">
      {/* Add Square branding */}
      <div className="gap-2 mb-0 flex items-center flex-row mx-auto">
        <img
          src={squareLogo}
          alt="Built with Square" 
          className="w-[150px]"
        />
        <p className="text-lg text-black">Payments securely processed by Square</p>
        <img
          src={sslSecureBadge}
          alt="SSL Secure" 
          className="w-[125px] ml-auto mt-1 "
        />
      </div>
      {steps.map((step, index) => (
        <div key={index} className="border border-gray-200">
          <button
            className={`w-full p-4 text-left font-semibold text-xl flex justify-between items-center  ${
              activeStep === index ? 'bg-green-500 text-white' : 'bg-white'
            } ${
              (activeStep < index) || (activeStep === 3) ? 'cursor-not-allowed' : ''
            }`}
            onClick={() => handleClick(index)}
          >
            <span>{step.title}</span>
          </button>
          {activeStep === index && (
            <div className="border-t border-gray-200">
              {step.content}
            </div>
          )}
        </div>
      ))}
      </div>
    </>
  );
}
