import React from 'react';
import CustomButton from './shared/CustomButton';
import { useModal } from '../contexts/ModalContext'; // Assuming you have this context

export default function LoginButton({ children, className, referrer }) {
  const { openLoginModal } = useModal();

  const handleClick = () => {
    openLoginModal(referrer);
  };

  return (
    <CustomButton 
      onClick={handleClick} 
      className={className}
    >
      {children}
    </CustomButton>
  );
} 