import { useState, useEffect, useRef } from 'react';
import SubHeader from '../components/global/SubHeader';
import UserMessage from '../components/shared/UserMessage';
import AddPayment from '../components/purchase/AddPayment';
import './Payment.css';

export default function Payment() {
    const [userMessage, setUserMessage] = useState('');
    const [messageType, setMessageType] = useState('info');

    return (
        <div className="h-auto w-full">
            <UserMessage
                message={userMessage}
                type={messageType}
            />
            <SubHeader />
            
            {/* Content area for each page */}
            <div className="flex flex-col md:flex-row bg-gray-200 h-screen min-h-[640px] min-w-[740px]">
                <div className="w-full flex justify-center items-center text-gray-500 text-center bg-white py-20">
                    <AddPayment />
                </div>
            </div>
        </div>
    );
}