import { useState, useEffect } from 'react';
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from 'react-icons/io';

const ImageSlider = ({ modifiedImageUrl }) => {
  // State to store the URLs of the images
  const [imageUrls, setImageUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zIndexClass, setZIndexClass] = useState('z-0');

  // Determine if the H3 title should be shown
  const showH3 = currentIndex > 0;

  // useEffect to add new URLs to imageUrls when modifiedImageUrl changes
  useEffect(() => {
    if (modifiedImageUrl && !imageUrls.includes(modifiedImageUrl)) {
      setImageUrls((prevUrls) => {
        if (!prevUrls.includes(modifiedImageUrl)) {
          const newUrls = [...prevUrls, modifiedImageUrl];
          setCurrentIndex(newUrls.length); // Set the index to the last element
          return newUrls;
        }
        return prevUrls; // Do nothing if the URL is already present
      });
    }
  }, [modifiedImageUrl, imageUrls]);

  // Update zIndexClass when showH3 changes
  useEffect(() => {
    if (showH3) {
      setZIndexClass('z-30'); // Apply immediately when showH3 is true
    } else {
      const timer = setTimeout(() => {
        setZIndexClass('z-0'); // Apply with a delay when showH3 is false
      }, 500); // 2-second delay

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showH3]);

  // Function to handle right button click
  const handleRightClick = () => {
    if (currentIndex < imageUrls.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Function to handle left button click
  const handleLeftClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="w-full absolute top-0 select-none">
      {/* Navigation Buttons */}
      <div className="absolute z-40 top-1 right-1 flex space-x-1">
        <IoIosArrowDropleftCircle
          size={40}
          className={`text-white cursor-pointer ${currentIndex === 0 ? 'opacity-50' : ''}`}
          onClick={handleLeftClick}
        />
        <IoIosArrowDroprightCircle
          size={40}
          className={`text-white cursor-pointer ${currentIndex === imageUrls.length ? 'opacity-50' : ''}`}
          onClick={handleRightClick}
        />
      </div>

      {/* Conditional Title */}
      {showH3 && (
        <h3 className="absolute top-2 bg-white text-black z-40 ml-2 px-1.5 py-0.5 rounded text-sm font-semibold font-sans pointer-events-none select-none">
          Modified Image
        </h3>
      )}

      {/* Image Container */}
      <div className={`relative w-full h-screen overflow-hidden pointer-events-none ${zIndexClass}`}>
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {/* Default Div Placeholder */}
          <div
            key="0"
            className="flex-shrink-0 w-full h-0 flex items-center justify-center bg-gray-200"
          ></div>

          {/* Modified Image Divs */}
          {imageUrls.map((url, index) => (
            <div
              key={index + 1} // Adjusted key to avoid collision with default div
              className="flex-shrink-0 w-full h-full flex items-center justify-center bg-gray-200"
            >
              <img
                src={url}
                alt={`Modified Image ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;