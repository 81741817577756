import React from 'react';
import { useModal } from '../../contexts/ModalContext';
import CustomButton from '../shared/CustomButton';
import { setHasSeenWelcomeModal } from '../../utils/session';

const WelcomeModal = () => {
  const { isWelcomeModalOpen, closeWelcomeModal } = useModal();

  const handleClose = () => {
    setHasSeenWelcomeModal(true);
    closeWelcomeModal();
  };

  if (!isWelcomeModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative">
        <h2 className="text-2xl font-bold mb-4 text-center">Welcome to ImagesForAds.Ai! 🎉</h2>
        
        <div className="space-y-4">
          <p className="text-lg text-gray-700">
            It's awesome that you have joined our community!
          </p>
          
          <div className="relative -mx-12 px-12 py-4 bg-lime-600 shadow-md transform -rotate-1 w-[calc(100%+6rem)] ">
            <p className="text-lg text-white transform rotate-1 font-normal">
              <span className="font-bold">Special Offer:</span> Get 50 additional credits for each piece of feedback you provide!
            </p>
          </div>
          
          <p className="text-lg text-gray-700">
            You can submit feedback any time by clicking Give Feedback! at the top of the page.
          </p>
        </div>

        <div className="mt-6">
          <CustomButton
            onClick={handleClose}
            className="w-full bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 justify-center text-white font-semibold border-none transition-all duration-200"
          >
            Let's Create Some Magic ✨
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;