// ../components/PromptSection.js
import React from 'react';

const PromptSection = ({
  prompt,
  setPrompt,
  performInpainting,
  isLoading,
  error,
}) => {
  return (
    <div className="w-72 ml-3 mr-5 flex flex-col">
      <h3 className='text-xl font-semibold mt-2 mb-1'>Fine-Tune Your Vision!</h3>
      <p className='mb-3 text-sm'>
        Draw over the image to highlight the areas you want to adjust. Then, use this field to guide the AI with specific, detailed instructions for enhancing or refining details. Be descriptive to get better results! 
        <br /><br />
        <span className='font-bold'>Example:</span>
        <br />
        ❌ <em>Make banana</em>
        <br />
        ✔️ <em>Make a large, yellow banana fruit on the table, with a smooth peel and realistic texture.</em>
      </p>
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe your modifications"
        className="w-full p-3 mb-2 resize-vertical border rounded-lg border-gray-300"
        rows={4}
      />
      <button
        onClick={performInpainting}
        disabled={isLoading}
        className={`mb-2 w-full p-2 ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-pink-500 hover:bg-pink-700 rounded text-white'}`}
      >
        {isLoading ? 'Processing...' : 'Fine Tune'}
      </button>
      {error && <div className="text-red-500 mb-2">{error}</div>}
    </div>
  );
};

export default PromptSection;