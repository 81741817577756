import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loginReferrer, setLoginReferrer] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const openLoginModal = (referrer = null) => {
    setLoginReferrer(referrer);
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    setLoginReferrer(null);
    console.log('closeLoginModal');
  };

  const openErrorModal = (message, detail) => {
    setErrorMessage(message);
    setErrorDetail(detail);
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage(null);
    setErrorDetail(null);
    console.log('closeErrorModal');
  };

  const openCouponModal = (referrer = null) => {
    setLoginReferrer(referrer);
    setIsCouponModalOpen(true);
  };

  const closeCouponModal = () => {
    setIsCouponModalOpen(false);
  };

  const openWelcomeModal = () => setIsWelcomeModalOpen(true);
  const closeWelcomeModal = () => setIsWelcomeModalOpen(false);

  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  const value = {
    isLoginModalOpen,
    loginReferrer,
    setLoginReferrer,
    openLoginModal,
    closeLoginModal,
    isErrorModalOpen,
    openErrorModal,
    closeErrorModal,
    errorMessage,
    setErrorMessage,
    errorDetail,
    setErrorDetail,
    isCouponModalOpen,
    openCouponModal,
    closeCouponModal,
    isWelcomeModalOpen,
    openWelcomeModal,
    closeWelcomeModal,
    isFeedbackModalOpen,
    openFeedbackModal,
    closeFeedbackModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}
