import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getApiUrl } from './config';

const savePaymentData = async (paymentData) => {
  try {
    const { token, details, companyName, firstNameOnCard, lastNameOnCard } = paymentData;
    const sessionData = jwtDecode(localStorage.getItem('token'));
    const { accountId, email, name, userId, picture } = sessionData;

    const url = `${getApiUrl()}/payments/save`;

    const res = await axios.post(url, {
      token,
      details,
      companyName,
      firstNameOnCard,
      lastNameOnCard,
      accountId,
      email,
      name,
      userId,
      picture
    });

    return res.data;
  } catch (error) {
    console.error('Error sending payment data:', error);
    // TODO: proper error
    throw error;
  }
};

export { savePaymentData };