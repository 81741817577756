// ../components/Modal.js
import React, { useEffect, useRef, useState } from 'react';

const Modal = ({ children, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    setIsVisible(true);

    // Function to handle outside click
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsVisible(false); // Hide the modal
        if (onClose) onClose(); // Call the onClose function if provided
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center transition-opacity duration-300 ease-out ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div
        ref={modalRef} // Reference to the modal content
        className={`relative bg-white rounded-lg w-[800px] xl:w-7/12 2xl:w-6/12 overflow-auto shadow-lg shadow-gray-700 transform transition-transform duration-300 ease-out ${
          isVisible ? 'scale-100' : 'scale-95'
        }`}
      >
        {/* Contenido del modal */}
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default Modal;