// src/components/Features.js
import React, { useState, useEffect } from 'react';

export default function Features() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(true);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    
    setIsVideoVisible(false);
  }, [activeIndex]);

  return (
    <section className="bg-gray-1000 text-white border-t border-gray-600">
      <div className="w-full mx-auto overflow-hidden">
        <div className="flex flex-col md:flex-row">
          {/* Left Column: Accordion */}
          <div className="w-full pr-0 ml-0 my-0 md:w-1/2 md:pr-8 md:my-20 md:ml-20 order-2 md:order-1">
            <div className="mb-4 border-b border-gray-600 pb-5 pt-5">
              <button
                className="w-full text-left px-4 rounded-lg focus:outline-none flex justify-between items-center"
                onClick={() => toggleAccordion(0)}
              >
                <span className={`text-xl md:text-2xl font-semibold ${activeIndex === 0 ? 'text-white' : 'text-gray-500'
                  }`}>
                  Fine-Tuning Your Ideas
                </span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-200 text-pink-500 ${
                    activeIndex === 0 ? 'hidden' : 'rotate-0'
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={4}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button>
              <div
                className={`mt-2 overflow-hidden transition-all duration-500 pb-2 ${
                  activeIndex === 0 ? 'max-h-60 opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <p className="px-4 rounded-b-lg text-gray-400 text-xl mb-4">
                  Easily tweak and adjust your visuals to align with your brand’s unique style and make sure every image hits the mark.
                </p>
              </div>
            </div>

            <div className="mb-4 border-b border-gray-600 pb-5 pt-5">
              <button
                className="w-full text-left px-4 rounded-lg focus:outline-none flex justify-between items-center"
                onClick={() => toggleAccordion(1)}
              >
                <span className={`text-xl md:text-2xl font-semibold ${activeIndex === 1 ? 'text-white' : 'text-gray-500'
                  }`}>
                  Social Media Ready
                </span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-200 text-pink-500 ${
                    activeIndex === 1 ? 'hidden' : 'rotate-0'
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={4}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button>
              <div
                className={`mt-2 overflow-hidden transition-all duration-500 pb-2 ${
                  activeIndex === 1 ? 'max-h-60 opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <p className="px-4 rounded-b-lg text-gray-400 text-xl mb-4">
                  Tailored for TikTok, Instagram, LinkedIn, and all the rest. Our visuals are engineered to stand out and perform.
                </p>
              </div>
            </div>

            <div className="mb-4 border-b border-gray-600 pb-5 pt-5">
              <button
                className="w-full text-left px-4 rounded-lg focus:outline-none flex justify-between items-center"
                onClick={() => toggleAccordion(2)}
              >
                <span className={`text-xl md:text-2xl font-semibold ${activeIndex === 2 ? 'text-white' : 'text-gray-500'
                  }`}>
                  Smart Campaign Setup
                </span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-200 text-pink-500 ${
                    activeIndex === 2 ? 'hidden' : 'rotate-0'
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={4}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button>
              <div
                className={`mt-2 overflow-hidden transition-all duration-500 pb-2 ${
                  activeIndex === 2 ? 'max-h-60 opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <p className="px-4 rounded-b-lg text-gray-400 text-xl mb-4">
                  Tell us your campaign goals, and our AI generates image content that’s not just good—it’s smart, relevant, and ready to captivate.
                </p>
              </div>
            </div>
          </div>

          {/* Right Column: Video */}
          <div className="w-full h-screen md:w-full mt-8 md:mt-0 order-1 md:order-2">
            <div className="relative w-full h-full">
              <div className="absolute top-0 left-0 w-full h-full shadow-[inset_0_0_100px_rgba(0,0,0,0.9)] z-10"></div>
              <video
                src={
                  activeIndex === 0
                    ? '/videos/fix.mp4'
                    : activeIndex === 1
                    ? '/videos/socialready.mp4'
                    : '/videos/smart.mp4'
                }
                className={`absolute top-0 left-0 w-full h-full object-cover transition-all duration-500 ${
                  isVideoVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-110'
                }`}
                autoPlay
                loop
                muted
                playsInline
                onLoadedData={() => setIsVideoVisible(true)} 
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}