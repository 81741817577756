import { useState, useEffect } from 'react';
import FactCarousel from './Facts'; 
import blockedWordsData from '../../utils/blockedWords.json';
import { RiCoinsFill } from "react-icons/ri";
import axios from 'axios';
import spinner from '../../images/spinner.svg';
import { getApiUrl } from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../contexts/ModalContext';

const AdForm = ({
    step,
    setStep,
    subject,
    setSubject,
    url,
    setUrl,
    objective,
    setObjective,
    adCopy,
    setAdCopy,
    loading,
    facts,
    handleFeedbackClick,
    setLoading,
    setImageUrl,
    setIsReady,
    setMessageType,
}) => {
    const [fadeIn, setFadeIn] = useState(true);
    const [userMessage, setUserMessage] = useState();
    const [modalMessageType, setModalMessageType] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { openErrorModal } = useModal();
    const navigate = useNavigate();

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const handleNext = (event) => {
        event.preventDefault();
        if (step < 4) {
            setFadeIn(false);
            setTimeout(() => {
                setStep(step + 1);
            }, 400);
        } else {
            handleSubmit(event);
        }
    };

    const handleBack = (event) => {
        event.preventDefault();
        if (step > 1) {
            setFadeIn(false);
            setTimeout(() => {
                setStep(step - 1);
            }, 400);
        }
    };

    useEffect(() => {
        setFadeIn(false);
        const timer = setTimeout(() => {
            setFadeIn(true);
        }, 300);
        return () => clearTimeout(timer);
    }, [step]);

    // Submit form
    const handleSubmit = async (e, retryCount = 0) => {
        e.preventDefault();
        setLoading(true);
        setImageUrl('');
        setIsReady(false);
    
        // Function to sanitize the user input to avoid triggering the content policy BEFORE sending it to OpenAI
        // The file is in blockedWords.json
        const sanitizeInput = (input, blockedWords) => {
            let sanitizedInput = input;
            blockedWords.forEach(word => {
                const regex = new RegExp(word, 'gi'); // Case-insensitive replacement
                sanitizedInput = sanitizedInput.replace(regex, '[censored]');
            });
            return sanitizedInput;
        };

        const containsBlockedWords = (input, blockedWords) => {
            return blockedWords.some(word => input.toLowerCase().includes(word.toLowerCase()));
        };
    
        // Load blocked words and sanitize the input to make sure none get passed to the OpenAI API
        const blockedWords = blockedWordsData.blockedWords;
        const sanitizedSubject = sanitizeInput(subject, blockedWords);
        const sanitizedObjective = sanitizeInput(objective, blockedWords);
        const sanitizedAdCopy = sanitizeInput(adCopy, blockedWords);

        if (
            containsBlockedWords(subject, blockedWords) ||
            containsBlockedWords(objective, blockedWords) ||
            containsBlockedWords(adCopy, blockedWords)
        ) {
            setLoading(false);
            setUserMessage("restricted_words");
            setMessageType("error");
            return; // Stop form submission
        }
    
        const userInput = {
            subject: sanitizedSubject,
            url, // URL doesn't need sanitization, lol
            objective: sanitizedObjective,
            ad_copy: sanitizedAdCopy
        };
        const maxRetries = 0; // Maximum number of retries. We can setup a few more in case our server is going bonkers and packages are getting lost.

        try {
          const response = await axios.post(`${getApiUrl()}/generate`, {
            userInput,
            currentUser: localStorage.getItem('currentUser'),
            account: localStorage.getItem('account')
          });

          if (response.status !== 200) {
            if(response.status === 403){
                localStorage.setItem('showSessionAlert', true);
                navigate("/logout");
                return null;
            }
            else {
                const errorResponse = await response?.data;
                let errorMessage = errorResponse.message || 'An unknown error occurred.';

                setLoading(false);
                setUserMessage(errorMessage);
                setMessageType("error");
                return;
            }
          }
          const responseData = await response.data;

          // TODO: REFACTOR FOR NEW MODAL SYSTEM
          if (responseData.isError) {

            setUserMessage(responseData.message);
            setModalMessageType('warning');
            setIsModalOpen(true);
            setIsReady(true);
            setLoading(false);
            return;
          }
          const img = new Image();
          img.src = responseData.imageUrl;

          localStorage.setItem('account', JSON.stringify(responseData.account));
          delete responseData.account;
          localStorage.setItem('currentImageData', JSON.stringify(responseData));

          img.onload = () => {
              setImageUrl(responseData.imageUrl);
              setIsReady(true);
              setLoading(false);
          };
        } catch (error) {

          if (process.env.REACT_APP_ENV === 'dev') {
            openErrorModal('API error', error.message);
            setIsReady(true);
            setLoading(false);
            return;
          } else {
            if (retryCount < maxRetries) {
              console.log(`Retrying... (${retryCount + 1}/${maxRetries})`);
              handleSubmit(e, retryCount + 1);
            } else {
                setLoading(false);
                setUserMessage(error.message); 
                setMessageType('error');
                setModalMessageType('error');
            }
            }
        }
    
        const resultsElement = document.getElementById('results');
        const resultsBtn = document.getElementById('results_btn');
    
        if (window.innerWidth < 768) {
            document.body.style.setProperty('overflow-y', 'scroll', 'important');
            resultsElement.classList.remove('hidden');
            resultsElement.classList.add('sliderUp');
            resultsBtn.classList.remove('hidden');
            resultsBtn.classList.add('sliderUp');
        }
    };

    return (
        <>
            {window.innerWidth < 768 ? (
                <div className="ad-form flex items-center justify-center min-h-screen mt-[-90px]">
                    {loading && (
                        <div
                            id="generating"
                            className={`w-screen h-screen absolute z-10 bg-gradient-to-r from-amber-500 to-pink-500 text-md text-white text-center left-0 top-0 flex items-center justify-center transition-opacity duration-500 ease-in-out`}
                        >
                            <div className="animate-scale-fade-in">
                                <img src={spinner} alt="Loading spinner" className="mx-auto mb-3" />
                                <FactCarousel facts={facts} duration={5000} />
                            </div>
                        </div>
                    )}

                    <div className={`step-form transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                        <form id="profileForm" onSubmit={handleNext} className="space-y-6">
                            {step === 1 && (
                                <div>
                                    <h2 className="text-3xl mb-3 font-semibold">Tell us what you're advertising</h2>
                                    <input
                                        type="text"
                                        placeholder="product/service/brand"
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                    <button
                                        type="submit"
                                        disabled={!subject.trim()}
                                        className={`w-full text-white font-bold py-4 px-4 rounded mt-4 ${!subject.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}

                            {step === 2 && (
                                <div>
                                    <h2 className="text-3xl mb-3 font-semibold">Tell us about your ad campaign</h2>
                                    <input
                                        type="text"
                                        placeholder="objective"
                                        value={objective}
                                        onChange={(e) => setObjective(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Ad Copy"
                                        value={adCopy}
                                        onChange={(e) => setAdCopy(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md mt-4"
                                    />
                                    <button
                                        type="submit"
                                        disabled={!objective.trim() || !adCopy.trim()}
                                        className={`w-full text-white font-bold py-4 px-4 rounded ${!objective.trim() || !adCopy.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                    >
                                        Next
                                    </button>
                                    <a
                                        href="#!"
                                        onClick={handleBack}
                                        className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                    >
                                        Go Back
                                    </a>
                                </div>
                            )}

                            {step === 3 && (
                                <div>
                                    <h2 className="text-3xl mb-3 font-semibold">Where will your ad point to?</h2>
                                    <input
                                        type="text"
                                        placeholder="URL"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                        required
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                    <button
                                        type="submit"
                                        disabled={!url.trim()}
                                        className={`w-full text-white font-bold py-4 px-4 rounded mt-3 ${!url.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                    >
                                        Next
                                    </button>
                                    <a
                                        href="#!"
                                        onClick={handleBack}
                                        className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                    >
                                        Go Back
                                    </a>
                                </div>
                            )}

                            {step === 4 && (
                                <div>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className={`w-full px-7 py-4 text-2xl rounded-md hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700 ${loading ? 'cursor-not-allowed text-white bg-gradient-to-r from-stone-500 to-stone-700' : 'bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white'}`}
                                    >
                                        Ignite the AI Genius
                                    </button>
                                    <a
                                        href="#!"
                                        onClick={handleBack}
                                        className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                    >
                                        Go Back
                                    </a>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            ) : (
                <form id="profileForm" onSubmit={handleSubmit} className="space-y-5">
                    <div className="section ad space-y-1">
                        <h2 className="text-lg md:text-lg lg:text-md xl:text-lg font-semibold">What are you advertising?</h2>
                        <div className="accordion">
                            <input
                                type="text"
                                name="subject"
                                placeholder="product/service/brand"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                                className="w-full lg:text-lg p-3 border border-gray-300 rounded-md focus:outline-none"
                            />
                            <p className="text-gray-500 text-lg mb-4">Name and a short description</p>
                            <input
                                type="text"
                                name="url"
                                placeholder="URL"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                required
                                className="w-full lg:text-lg p-3 border border-gray-300 rounded-md focus:outline-none"
                            />
                            <p className="text-gray-500 text-lg">For your product or service</p>
                        </div>
                    </div>

                    <div className="section campaign space-y-1">
                        <h2 className="text-lg md:text-lg lg:text-md xl:text-lg font-semibold">Tell us about your ad campaign</h2>
                        <div className="accordion">
                            <input
                                type="text"
                                name="objective"
                                placeholder="objective"
                                value={objective}
                                onChange={(e) => setObjective(e.target.value)}
                                required
                                className="w-full lg:text-lg p-3 border border-gray-300 rounded-md focus:outline-none"
                            />
                            <p className="text-gray-500 text-lg mb-4">Main goal of your ad campaign</p>
                            <input
                                type="text"
                                name="adCopy"
                                placeholder="Ad Copy"
                                value={adCopy}
                                onChange={(e) => setAdCopy(e.target.value)}
                                required
                                className="w-full lg:text-lg p-3 border border-gray-300 rounded-md focus:outline-none"
                            />
                            <p className="text-gray-500 text-lg">The text for your ad</p>
                        </div>
                    </div>

                    <div className="section submit space-y-2  2xl:pt-15">
                    <button
                            type="submit"
                            disabled={loading}
                            className={`w-full py-4 text-2xl md:text-lg lg:text-xl rounded-md flex items-center justify-center font-normal ${
                                loading
                                    ? 'cursor-not-allowed text-white bg-gradient-to-r from-stone-500 to-stone-700'
                                    : 'bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700'
                            }`}
                        >
                            Ignite the AI Genius <RiCoinsFill size={25} className='ml-3.5 mr-0.5' /> <span className='font-bold'>5</span>
                        </button>
                        <p className="text-center">
                            <button
                                onClick={handleFeedbackClick}
                                className="flex justify-center flex-row w-full block bg-lime-600 py-3 rounded-md text-white font-normal focus:outline-none mx-auto text-center hover:bg-gray-700 transition-all duration-300 weight-600"
                                type="button"
                            >
                                <RiCoinsFill size={25} className='' />&nbsp;<span className="inline-block mx-[5px] font-normal text-xl">Get Free Credits!</span>
                            </button>
                        </p>
                    </div>
                </form>
            )}
              {/* <ErrorModal
                message={userMessage}
                type={modalMessageType}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
              /> */}
        </>
    );
};

export default AdForm;