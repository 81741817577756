import { useState, useEffect, useRef } from 'react';
import SubHeader from '../components/global/SubHeader';
import UserMessage from '../components/shared/UserMessage';
import AddPayment from '../components/purchase/AddPayment';
import Pricing from '../components/purchase/Pricing';
import Footer from '../components/global/Footer';
import PurchaseFlow from '../components/purchase/PurchaseFlow';

export default function TemplatePage({ pageName }) {
    const [userMessage, setUserMessage] = useState('');
    const [messageType, setMessageType] = useState('info');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="h-auto w-full">
                <UserMessage
                message={userMessage}
                type={messageType}
            />
              <SubHeader />
              {/* Content area for each page */}
              <div className="flex flex-col md:flex-row bg-gray-950 min-h-[640px] min-w-[740px]">
                  {/* <div className="w-full flex text-gray-500 text-center bg-gray-950 text-center"> */}
                  <div className="w-full flex justify-center items-center text-gray-500 text-center bg-white py-20">
                    {pageName === 'pricing' && (
                      <Pricing />
                    )}

                    {pageName === 'credits' && (
                      <Pricing />
                    )}

                    {pageName === 'purchase' && (
                      <PurchaseFlow />
                    )}
{/* 
                    {pageName === 'receipt' && (
                      <Receipt />
                    )}

                    {pageName === 'howItWorks' && (
                      <HowItWorks />
                    )} */}
                  </div>
              </div>
          </div>
        </>
    );
}