import { useEffect } from 'react';
import { useCheckout } from '../../../contexts/CheckoutContext';
import ProCard from './ProCard';

export default function ProCartItem() {
  const { quantity, setQuantity, setFrequencyText, frequencyText, setCreditCount, creditCount, setCheckoutTotal, checkoutTotal } = useCheckout();
  const ITEM_PRICE = 9; // in US dollars
  const CREDITS_PER_ITEM = 90;
  const FREQUENCY_TEXT = 'Monthly until cancelled';
  useEffect(() => {
    setQuantity(1);
    setFrequencyText(FREQUENCY_TEXT);
    setCreditCount(quantity * CREDITS_PER_ITEM);
    setCheckoutTotal(quantity * ITEM_PRICE);
  }, []);

  return (
    <>
      <div className="w-[50%]">
        <ProCard />
      </div>
      <div className="w-[50%] p-0">
        <table className="w-full mb-6 text-lg mx-4 text-black">
          <tbody>
            <tr className="border-b">
              <td className="py-2">Quantity</td>
              <td className="py-2">{quantity}</td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Frequency</td>
              <td className="py-2">{frequencyText}</td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Tax</td>
              <td className="py-2">$0</td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Credits</td>
              <td className="py-2">{quantity * CREDITS_PER_ITEM} credits</td>
            </tr>
            <tr className="border-b font-bold">
              <td className="py-2">Total</td>
              <td className="py-2">${quantity * ITEM_PRICE}.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
} 