import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getApiUrl } from './config';

let interceptor = null;
const logIn = async (googleResponse) => {
  const authenticationResponse = await axios.post(`${getApiUrl()}/authenticate`, 
    googleResponse, 
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  const response = await authenticationResponse.data;
  console.log(">>> res from server", response);
  if (response?.authenticated) {
    const currentUser = response.currentUser;
    currentUser.isNewUser = currentUser.lastModifiedAt === currentUser.createdAt;
    currentUser.hasSeenWelcomeModal = false;
    console.log(">>> currentUser", currentUser.isNewUser);
    initInterceptor();
    const profilePic = response.currentUser.picture || "https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg";
    localStorage.setItem('currentUserPic', profilePic);
    localStorage.setItem('token', response.token);
    localStorage.setItem('currentUser', JSON.stringify(response.currentUser));
    localStorage.setItem('account', JSON.stringify(response.account));
    localStorage.setItem('creditBalance', response.account?.creditBalance || 0);
    return true;
  }
  return false;
};

const logOut = async () => {
  await localStorage.removeItem('token');
  await localStorage.removeItem('currentUserPic');
  await localStorage.removeItem('currentUser');
  await localStorage.removeItem('account');
  await localStorage.removeItem('creditBalance');
  await localStorage.removeItem('isNewUser');
  await localStorage.removeItem('hasSeenWelcomeModal');
  await localStorage.removeItem('hasSeenWelcomeModal');
  return true;
};

const getSession = () => {
  return {
    token: localStorage.getItem('token'),
    currentUserPic: localStorage.getItem('currentUserPic'),
    currentUser: JSON.parse(localStorage.getItem('currentUser')),
    account: JSON.parse(localStorage.getItem('account')),
    creditBalance: localStorage.getItem('creditBalance'),
    isNewUser: localStorage.getItem('isNewUser'),
    hasSeenWelcomeModal: localStorage.getItem('hasSeenWelcomeModal')
  };
};

const setSessionCreditBalance = (newCreditBalance) => {
  localStorage.setItem('creditBalance', newCreditBalance);
};

const getSessionCreditBalance = () => {
  return localStorage.getItem('creditBalance');
};

const getHasSeenWelcomeModal = () => {
  const session = getSession()
  return session?.currentUser?.hasSeenWelcomeModal;
};

const setHasSeenWelcomeModal = (value) => {
  const currentUser = getSession().currentUser;
  if (currentUser) {
    currentUser.hasSeenWelcomeModal = value;
  }
  localStorage.setItem('currentUser', JSON.stringify(currentUser));
};

const initInterceptor = async () => {
  const token = localStorage.getItem('token');
  if(token && !isTokenValid(token)){
      localStorage.setItem('showSessionAlert', true);
      logOut();
  };
  if (!!interceptor) {
    return interceptor;
  }
  console.log('init interceptor');
  interceptor = await axios.interceptors.request.use(
    config => {
      config.validateStatus = function (status) {
        return status <= 500; // default
      };
      // Get the token from localStorage (or another secure storage)

      // If the token exists, attach it to the Authorization header
      if (token) {
          config.headers.Authorization = `Bearer ${token}`;
      }
      return config; // Return the modified config to send the request
    },
    error => {
      // Handle errors before they reach the application
      return Promise.reject(error);
    }
  );
  return interceptor;
};

const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const { exp } = jwtDecode(token); // Decode the token to get the expiration
    if (!exp) return false;

    // Check if the token is expired
    const isExpired = Date.now() >= exp * 1000;
    return !isExpired;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
};

export { logIn, logOut, getSession, isTokenValid, initInterceptor, setSessionCreditBalance, getSessionCreditBalance, getHasSeenWelcomeModal, setHasSeenWelcomeModal };
