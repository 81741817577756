import React from "react";
import axios from "axios";
import { MdCloudDownload } from "react-icons/md";
import { emitTrackEvent } from "../../utils/tracking";

const DownloadButton = ({ imageUrl }) => {
  const eventKey = "beta_create_download_image";

  const handleDownload = (imageUrl) => {
    const fileName = "ad-image.png";

    axios
      .get(imageUrl, { responseType: "blob" })
      .then((response) => {
        const blobUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);

        const currentImageData = JSON.parse(
          localStorage.getItem("currentImageData")
        );
        emitTrackEvent(eventKey, currentImageData);
      })
      .catch((error) => console.error("Failed to download image", error));
  };
  return (
    <>
      <button
        className="w-auto px-5 bg-transparent border-2 border-pink-500 text-white py-1.5 rounded-md flex items-center mx-2 hover:bg-pink-500"
        onClick={(e) => handleDownload(imageUrl)}
      >
        <MdCloudDownload className="mr-2 text-2xl" />
        Download
      </button>
    </>
  );
};

export default DownloadButton;
