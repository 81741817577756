import React from 'react';
import { RiCoinsFill } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

export default function StarterCard() {
  const navigate = useNavigate();

  return (
    <button
      className="w-full bg-gradient-to-r from-pink-500 to-yellow-500 text-white py-8 rounded-xl transition-colors duration-200 font-bold"
      onClick={() => navigate('/purchase/starter')}
    >
      <span className="text-6xl font-bold">$7</span>{" "}
      <span className="text-xl font-semibold">one-time</span>
      <div className="text-md font-semibold rounded-full bg-gray-800 pt-3 pb-2 max-w-[80%] px-10 2xl:max-w-[80%] mx-auto mt-5 text-center border-4 border-yellow-200">
        <div className="flex items-center justify-center">
          <RiCoinsFill size={35} className="text-yellow-200" />
          <span className="text-lg font-semibold flex items-center">
            <IoIosClose size={30} /> <span className='text-2xl'>50</span>
          </span>
        </div>

        <span className="block font-normal text-gray-300">
          Buy as You Go
        </span>
      </div>
    </button>
  );
} 