import { useEffect } from 'react';
import { useModal } from '../../contexts/ModalContext';

export default function ErrorModal() {
    const { isErrorModalOpen, closeErrorModal, openErrorModal, errorMessage, errorDetail } = useModal();

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                closeErrorModal();
            }
        };
        
        if (isErrorModalOpen) {
            document.addEventListener('keydown', handleEscape);
        }
        
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [isErrorModalOpen, closeErrorModal]);

    useEffect(() => {
        console.log('Modal open state:', isErrorModalOpen);
    }, [isErrorModalOpen]);

    if (!isErrorModalOpen) return null;

    return (
        <div className="fixed inset-0 overflow-y-auto z-99">
            {/* Overlay */}
            <div 
                className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
                onClick={closeErrorModal}
            />
            
            {/* Modal */}
            <div className="flex min-h-full items-center justify-center p-4">
              <div>
                <div className="relative bg-gray-800 rounded-t-lg p-4 max-w-lg w-full shadow-xl">
                    {/* Close button */}
                    <button
                        onClick={closeErrorModal}
                        className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
                        aria-label="Close modal"
                    >
                        <svg 
                            className="w-6 h-6" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>

                    <h2 className="text-2xl font-bold text-white">{errorMessage}</h2>
                </div>
                <div className="relative rounded-b-lg p-4 max-w-lg w-full bg-white mt-0">
                  <p className="text-black-400 text-xl p-4 mb-4">{errorDetail}</p>

                  <button
                    onClick={closeErrorModal}
                    className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors w-full text-xl font-semibold"
                  >
                    Dismiss
                  </button>

                </div>
              </div>
            </div>
        </div>
    );
} 