import React, { useState } from 'react';
import { useModal } from '../../contexts/ModalContext';
import CustomButton from '../shared/CustomButton';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { getApiUrl } from '../../utils/config';
import { setSessionCreditBalance } from '../../utils/session';
import { toast } from 'react-toastify';

const FeedbackModal = () => {
  const { isFeedbackModalOpen, closeFeedbackModal, openLoginModal, openErrorModal } = useModal();
  const { isLoggedIn, currentUser, account, setCreditBalance } = useAuth();
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${getApiUrl()}/feedback`, {
        userId: currentUser._id,
        accountId: account._id,
        userFeedback: feedback
      });

      if (response.status === 200) {
        const newCreditBalance = response.data.newCreditBalance;
        const creditsAdded = response.data.creditsAdded;
        setSessionCreditBalance(newCreditBalance);
        setCreditBalance(newCreditBalance);
        toast.info(`${response.data.feedbackResponseMessage} We've added ${creditsAdded} credits to your account! Your new balance is ${newCreditBalance}.`);
        handleCloseModal();
      } else {
        const errorMessage = response.data?.error || response.error || 'An error occurred while submitting feedback';
        const errorTitle = response.data?.errorTitle || response.errorTitle || 'Error';
        handleCloseModal();
        openErrorModal(errorTitle, errorMessage);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      openErrorModal('Error submitting feedback', error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setFeedback('');
    closeFeedbackModal();
  };

  if (!isFeedbackModalOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={handleCloseModal}
    >
      <div 
        className="bg-white rounded-lg p-8 max-w-md w-full mx-4 relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button */}
        <button
          onClick={handleCloseModal}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-4 text-center">We'd Love Your Feedback! 💭</h2>
        
        <div className="space-y-5">
          {isLoggedIn ? (
            <>
              <p className="text-lg text-gray-700">
                Your feedback helps us improve <strong>ImagesForAds.Ai</strong> for everyone.
              </p>
              
              <div className="relative -mx-12 px-12 py-4 bg-lime-600 shadow-md transform -rotate-1 w-[calc(100%+6rem)] mb-8">
                <p className="text-lg text-white font-normal transform rotate-1">
                  <span className="font-bold">Bonus:</span> Get 50 credits instantly added to your balance for your feedback!
                </p>
              </div>

              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Tell us what you think..."
                className="w-full h-32 p-4 border border-gray-400 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent resize-none text-lg placeholder:text-gray-500 placeholder:text-lg"
              />

              <div className="mt-6">
                <CustomButton
                  onClick={handleSubmit}
                  disabled={!feedback.trim() || isSubmitting}
                  className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 py-2 rounded-lg hover:bg-gradient-to-l hover:from-pink-600 hover:to-purple-600 font-normal border-none"
                >
                  {isSubmitting ? (
                    <div className="flex items-center justify-center">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2" />
                      Submitting...
                    </div>
                  ) : (
                    'Submit Feedback'
                  )}
                </CustomButton>
              </div>
            </>
          ) : (
            <>
              <p className="text-lg text-gray-700 text-center">
                Please log in first so we know where to send your free credits when you submit your feedback!
              </p>
              
              <div className="relative -mx-12 px-12 py-4 bg-lime-600 shadow-md transform -rotate-1 w-[calc(100%+6rem)] mb-12">
                <p className="text-lg text-white font-normal transform rotate-1">
                  <span className="font-bold">Bonus:</span> Get 50 credits instantly added to your balance for your feedback!
                </p>
              </div>

              <div className="mt-6">
                <CustomButton
                  onClick={() => {
                    handleCloseModal()
                    openLoginModal('/feedback');
                  }}
                  className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white px-4 py-2 rounded-lg hover:bg-gradient-to-l hover:from-pink-600 hover:to-purple-600 font-normal border-none"
                >
                  Login or Register
                </CustomButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal; 