import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useModal } from '../contexts/ModalContext';
import { getHasSeenWelcomeModal, setHasSeenWelcomeModal } from '../utils/session';

export const useWelcomeModal = () => {
  const { isLoggedIn, currentUser } = useAuth();
  const { openWelcomeModal, closeWelcomeModal } = useModal();

  useEffect(() => {
    const hasSeenWelcomeModal = getHasSeenWelcomeModal();
    if (isLoggedIn && currentUser.isNewUser && !hasSeenWelcomeModal) {
      openWelcomeModal();
      setHasSeenWelcomeModal(true);
    }
  }, [isLoggedIn, currentUser, openWelcomeModal]);
}; 