import { useEffect, useState } from 'react';
import { useCheckout } from '../../contexts/CheckoutContext';
import { useAuth } from '../../contexts/AuthContext';
import { addMonths, format } from 'date-fns';
import Unlimited from '../shared/Unlimited';;

export default function Receipt() {
  const { creditCount, planType, checkoutTotal, squarePaymentId, isUnlimited } = useCheckout();
  const { creditBalance } = useAuth();
  const [displayCreditsAdded, setDisplayCreditsAdded] = useState(null);
  const [displayCreditBalance, setDisplayCreditBalance] = useState(null);

  useEffect(() => {
    setDisplayCreditsAdded(isUnlimited ? <Unlimited /> : `${creditCount} credits`);
    setDisplayCreditBalance(isUnlimited ? 'Unlimited credits' : `${creditBalance + creditCount} credits`);
  }, [isUnlimited, creditCount]);

  const getRenewalDate = () => {
    const today = new Date();

    switch (planType) {
      case 'starter':
        return 'none';
      case 'pro':
        return format(addMonths(today, 1), 'MM/dd/yyyy');
      case 'unlimited':
        return format(addMonths(today, 3), 'MM/dd/yyyy');
      default:
        return 'none';
    }
  };

  return (
    <div className="p-6 space-y-4">
      <h2 className="text-2xl font-bold text-center mb-6">Purchase Complete</h2>
      
      <div className="space-y-2 text-xl">
        <div className="flex justify-between">
          <span className="text-gray-600">Credits added to your balance:</span>
          <span className="font-semibold">{displayCreditsAdded}</span>
        </div>

        <div className="flex justify-between">
          <span className="text-gray-600">New credit balance:</span>
          <span className="font-semibold">{displayCreditBalance}</span>
        </div>

        <div className="flex justify-between">
          <span className="text-gray-600">Renewal date:</span>
          <span className="font-semibold">{getRenewalDate()}</span>
        </div>

        <hr className="my-4" />
        
        <div className="flex justify-between">
          <span className="text-gray-600">Total charged:</span>
          <span className="font-semibold">${checkoutTotal}.00</span>
        </div>

        <div className="flex justify-between">
          <span className="text-gray-600">Transaction ID (processed by Square):</span>
          <span className="font-mono text-sm">{squarePaymentId}</span>
        </div>
      </div>
      <br /><br />
      <div className="mt-22 text-center">
        <p className="text-gray-600 text-2xl">Thank you for your purchase!</p>
      </div>
      <button
        onClick={() => window.location.href = '/create'}
        className="w-full mt-8 bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition-colors font-semibold text-xl"
      >
        Now let's create some images!
      </button>
    </div>
  );
} 