import { createContext, useState, useContext } from 'react';

export const CheckoutContext = createContext();
console.log('CheckoutContext');
export function CheckoutProvider({ children }) {
  const [checkoutTotal, setCheckoutTotal] = useState(0); 
  const [activeStep, setActiveStep] = useState(0);
  const [thirdPartyPaymentToken, setThirdPartyPaymentToken] = useState(null);
  const [planType, setPlanType] = useState(null);
  const [creditCount, setCreditCount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [frequencyText, setFrequencyText] = useState(null);
  const [formData, setFormData] = useState({});
  const [showCurrentPayment, setShowCurrentPayment] = useState(false);
  const [squarePayment, setSquarePayment] = useState(null);
  const [squarePaymentId, setSquarePaymentId] = useState(null);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const value = {
    checkoutTotal,
    setCheckoutTotal,
    activeStep,
    setActiveStep,
    thirdPartyPaymentToken,
    setThirdPartyPaymentToken,
    planType,
    setPlanType,
    creditCount,
    setCreditCount,
    quantity,
    setQuantity,
    frequencyText,
    setFrequencyText,
    formData,
    setFormData,
    showCurrentPayment,
    setShowCurrentPayment,
    squarePayment,
    setSquarePayment,
    squarePaymentId,
    setSquarePaymentId,
    isUnlimited,
    setIsUnlimited
  };

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
}

export function useCheckout() {
  const context = useContext(CheckoutContext);
  if (context === undefined) {
    throw new Error('useCheckout must be used within an CheckoutProvider');
  }
  return context;
}
