import React from 'react';
import { useCheckout } from '../../contexts/CheckoutContext';
import { 
  FaCreditCard, // Default card icon
  FaCcVisa,
  FaCcMastercard, 
  FaCcAmex, 
  FaCcDiscover 
} from 'react-icons/fa';

const Payment = ({ onRemove, onContinue }) => {
  const { formData, setActiveStep } = useCheckout();
  
  // Add null checks and default values
  const brand = formData?.brand || '';
  const lastFour = formData?.cardNumber;

  // Map card brands to their respective icons
  const cardIcons = {
    VISA: FaCcVisa,
    MASTERCARD: FaCcMastercard,
    AMEX: FaCcAmex,
    DISCOVER: FaCcDiscover,
  };

  // Get the appropriate icon component with null safety
  const CardIcon = cardIcons[brand] || FaCreditCard;

  // Format card number with null safety
  const maskedNumber = `•••• •••• •••• ${lastFour}`;

  return (
    <div className="relative flex flex-col bg-[#f8f9fc] overflow-x-hidden" 
         style={{ fontFamily: "Work Sans, Noto Sans, sans-serif" }}>
      <div className="flex flex-col items-center py-5 px-40">
        <div className="w-[512px] max-w-[960px]">
          <h2 className="text-lg font-bold text-[#0e121b] pb-2">Payment Method</h2>
          
          <div className="flex items-center justify-between p-4 border rounded-xl bg-white">
            <div className="flex items-center gap-3">
              <CardIcon className="text-2xl text-gray-600" />
              <span className="font-medium">{maskedNumber}</span>
            </div>
            <div className="flex items-center gap-3">
              <span className="font-medium">{formData?.expiryDate}</span>
            </div>
            
            <button
              onClick={onRemove}
              className="px-3 py-1 text-sm text-red-600 hover:text-red-700 font-medium rounded-lg hover:bg-red-50 transition-colors"
            >
              Remove
            </button>
          </div>
          <button
            className="w-full flex-1 h-10 px-24 bg-[#195de6] text-[#f8f9fc] text-lg font-semibold rounded-md mt-4"
            onClick={onContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment; 