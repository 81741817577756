import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Create from './pages/Create';
import Payment from './pages/Payment';
import Logout from './components/Logout';
import './App.css';
import PageTemplate from './pages/PageTemplate';
import { ModalProvider, useModal } from './contexts/ModalContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoginModal from './components/LoginModal';
import ErrorModal from './components/modals/ErrorModal';
import { initInterceptor } from './utils/session';
import Footer from './components/global/Footer';
import { CheckoutProvider } from './contexts/CheckoutContext';
import { toast } from 'react-toastify';
import CouponModal from './components/modals/Coupon';
import FeedbackModal from './components/modals/FeedbackModal';

const ApplyBodyId = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.id = 'home-page';
    } else if (location.pathname === '/create') {
      document.body.id = 'create-page';
    } else if (location.pathname === '/credits') {
      document.body.id = 'credits-page';
    } else if (location.pathname === '/payment') {
      document.body.id = 'payments-page';
    } else if (location.pathname === '/how-it-works"') {
      document.body.id = 'how-it-works-page';
    }

    return () => {
      document.body.id = '';
    };
  }, [location]);

  return null;
};

function AppContent() {
  const { isLoggedIn } = useAuth();
  const { openLoginModal } = useModal();

  // Interceptor is initialized via the session util when user logs in.
  //   However, if the user is already logged in when they load the page,
  //   we need to initialize the interceptor manually.
  if (isLoggedIn) {
    initInterceptor();
  }
  else{
    // check for alert
    if(localStorage.getItem('showSessionAlert')){
      toast.info("Your session has expired. Please log in again.");
      localStorage.removeItem('showSessionAlert');
    }
  }

  const ProtectedRoutes = () => {
    if (!isLoggedIn) {
      openLoginModal();
    } else {
      return <Outlet />;
    }
  };

  return (
    <Router>
      <ApplyBodyId />
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/payment" element={<Payment />} />
          <Route path="/purchase/*" element={<PageTemplate pageName={'purchase'} />} />
          <Route path="/create" element={<Create />} />
        </Route>
        <Route path="/" element={<HomePage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/credits" element={<PageTemplate pageName={'credits'} />} />
        <Route path="/how-it-works" element={<PageTemplate pageName={'howItWorks'} />} />
      </Routes>
      <LoginModal />
      <ErrorModal />
      <CouponModal />
      <FeedbackModal />
      <Footer />
    </Router>
  );
}

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <AuthProvider>
        <ModalProvider>
          <CheckoutProvider>
            <AppContent />
          </CheckoutProvider>
        </ModalProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
