import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ThumbsComponent from './ThumbsComponent';

const ImageZoom = ({ imageUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const handleZoomIn = () => {
    setScale(prev => Math.min(prev + 0.5, 3));
  };

  const handleZoomOut = () => {
    setScale(prev => Math.max(prev - 0.5, 1));
  };

  const handleClose = () => {
    setIsOpen(false);
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const preventRightClick = (e) => {
    e.preventDefault();
    return false;
  };

  const preventDragStart = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <ThumbsComponent />
      <img
        src={imageUrl}
        alt="Zoomable"
        className="cursor-pointer rounded-lg transition-all duration-200 w-full h-full object-cover hover:opacity-80 hover:scale-[1.02] hover:shadow-lg"
        onClick={() => setIsOpen(true)}
        onContextMenu={preventRightClick}
        onDragStart={preventDragStart}
        draggable="false"
      />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90"
            onClick={handleClose}
            onContextMenu={preventRightClick}
          >
            <div className="absolute top-4 right-4 z-50 flex gap-2 bg-black/80 p-2 rounded-lg shadow-lg backdrop-blur-sm">
              <button
                className="bg-white/20 hover:bg-white/40 text-white px-4 py-2 rounded-md font-medium transition-colors duration-200 flex items-center justify-center min-w-[100px] shadow-lg border border-white/10"
                onClick={(e) => {
                  e.stopPropagation();
                  handleZoomIn();
                }}
              >
                Zoom +
              </button>
              <button
                className="bg-white/20 hover:bg-white/40 text-white px-4 py-2 rounded-md font-medium transition-colors duration-200 flex items-center justify-center min-w-[100px] shadow-lg border border-white/10"
                onClick={(e) => {
                  e.stopPropagation();
                  handleZoomOut();
                }}
              >
                Zoom -
              </button>
              <button
                className="bg-red-500/80 hover:bg-red-600/80 text-white px-4 py-2 rounded-md font-medium transition-colors duration-200 flex items-center justify-center min-w-[100px] shadow-lg"
                onClick={handleClose}
              >
                Close
              </button>
            </div>

            <motion.div
              drag
              dragMomentum={false}
              dragElastic={0}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onClick={(e) => e.stopPropagation()}
              style={{
                scale,
                x: position.x,
                y: position.y,
                cursor: isDragging ? 'grabbing' : 'grab'
              }}
            >
              <img
                src={imageUrl}
                alt="Zoomed"
                className="max-h-[90vh] max-w-[90vw] object-contain"
                draggable="false"
                onContextMenu={preventRightClick}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ImageZoom;
