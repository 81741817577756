import { useEffect, useState } from 'react';
import { useCheckout } from '../../contexts/CheckoutContext';
import axios from 'axios';
import { getApiUrl } from '../../utils/config';
import { useModal } from '../../contexts/ModalContext';
import LoadingOverlay from '../shared/LoadingOverlay';
import { useAuth } from '../../contexts/AuthContext';
import { setSessionCreditBalance } from '../../utils/session';

export default function Checkout({ onContinue }) {
  const {
    checkoutTotal,
    thirdPartyPaymentToken,
    planType,
    creditCount,
    frequencyText,
    setSquarePaymentId,
    squarePaymentId,
    isUnlimited,
    activeStep,
    setActiveStep,
  } = useCheckout();
  const { openErrorModal, errorMessage, setErrorMessage, errorDetail, setErrorDetail } = useModal();
  const [loading, setLoading] = useState(false);
  const { account, currentUser, creditBalance, setCreditBalance } = useAuth();

  let recurrence;
  let planExpiryDate = null;
  useEffect(() => {
    switch (planType) {
      case 'starter':
        recurrence = 0;
        break;
      case 'pro':
        recurrence = 1;
        planExpiryDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
        break;
      case 'unlimited':
        recurrence = 3;
        planExpiryDate = new Date(new Date().setMonth(new Date().getMonth() + 3));
        break;
    }
  }, []);

  const processPayment = async () => {
    const totalInCents = checkoutTotal * 100;
    try {
      setLoading(true);
      const response = await axios.post(`${getApiUrl()}/payments`, {
        accountId: account._id,
        userId: currentUser._id,
        recurrence,
        amount: JSON.stringify(totalInCents),
        sourceId: thirdPartyPaymentToken,
        creditCount,
        squarePaymentId,
        isUnlimited,
        shouldRenew: planExpiryDate ? true : false,
        planExpiryDate,
      });

      if (response.status === 200) {
        // Handle successful payment
        const newCreditBalance = response.data.isUnlimited ? '*' : response.data.newCreditBalance;
        console.log('Payment processed successfully');
        setSquarePaymentId(response.data.squarePaymentId);
        setSessionCreditBalance(newCreditBalance);
        setCreditBalance(newCreditBalance);
        console.log(response.data.newCreditBalance);

        onContinue();
      } else {
        // Handle payment error
        openErrorModal(response.data.message, response.data.error);
      }
    } catch (error) {
      // Handle payment error
      console.error('Payment processing failed:', error);
      const errorMessage = error?.response?.data?.message || error?.message || 'Payment processing failed';
      const errorDetail = 'There was an error processing your payment. Please check your payment details and try again.';
      openErrorModal(errorMessage, errorDetail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(thirdPartyPaymentToken);

  }, [thirdPartyPaymentToken]);

  return (
    <div className="relative">
      <LoadingOverlay isLoading={loading} />
      <div className="space-y-4 p-4">
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Order Summary</h2>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Plan Type</span>
            <span className="font-medium capitalize">{planType}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Frequency</span>
            <span className="font-medium">{frequencyText}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Total Credits</span>
            <span className="font-medium">{isUnlimited ? 'Unlimited' : creditCount}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Total amount to be charged</span>
            <span className="text-lg font-bold">${checkoutTotal}.00</span>
          </div>
        </div>

        <div className="border-t pt-4">
          <button
            className="w-full font-semibold text-xl bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
            onClick={processPayment}
          >
            Submit Payment
          </button>
          <p className="mt-2 text-md text-gray-600">Your payment will be securely processed by Square. No sensitive information is stored on our servers.</p>
        </div>
      </div>
    </div>
  );
} 