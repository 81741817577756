import StarterCard from './plans/StarterCard';
import ProCard from './plans/ProCard';
import UnlimitedCard from './plans/UnlimitedCard';
import StarterCartItem from './plans/StarterCartItem';
import ProCartItem from './plans/ProCartItem';
import UnlimitedCartItem from './plans/UnlimitedCartItem';
import { useState, useContext, useEffect } from 'react';
import { useCheckout } from '../../contexts/CheckoutContext';

export default function Cart({ onContinue }) {
  const { setActiveStep, setPlanType, planType, setShowCurrentPayment } = useCheckout();

  useEffect(() => {
    const path = window.location.pathname.split('/');
    setPlanType(path[2]); // Assuming URL structure is /purchase/[planType]
    setShowCurrentPayment(true);
  }, []); // Empty dependency array since we only want this to run once on mount

  // Render different plan components based on URL segment
  const renderPlan = () => {
    switch (planType) {
      case 'starter':
        return <StarterCartItem />;
      case 'pro':
        return <ProCartItem />;
      case 'unlimited':
        return <UnlimitedCartItem />;
      default:
        return <div>Invalid plan selected</div>;
    }
  };

  return (
    <>
    <div className='flex justify-center p-4'>
      {renderPlan()}
    </div>
    <button 
      onClick={onContinue}
      className="mx-4 mb-4 w-[calc(100%-2rem)] bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 font-semibold text-lg"
    >
      Continue to review and submit
    </button>
    </>
  );
}
