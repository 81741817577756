// src/components/Footer.js

/**
 * Footer Component
 *
 * @component
 * @description This component renders the footer section of the website, which includes copyright information,
 * navigation links, and social media icons. The current year is dynamically displayed using JavaScript.
 * Note: The links containing `#!` are placeholders and should be replaced them!!!
 *
 * @returns {JSX.Element} The footer element with navigation and social media links.
 */

import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaArrowRight } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="bg-gray-900 py-6 mt-auto">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-200">
           ImagesForAds.Ai &copy; {new Date().getFullYear()} La Reverie, Studio. All rights reserved.
          </p>

          <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center">
            {/* TODO  update links https://www.youtube.com/watch?v=q_5dw4UPjtU  */}
            <div className="flex space-x-4">
              <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                Sitemap
              </a>
              <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                Privacy Policy
              </a>
              <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                Terms of Service
              </a>
            </div>

            {/* Social Media Icons */}
            {/* TODO  update links https://www.youtube.com/watch?v=q_5dw4UPjtU  */}
            <div className="flex space-x-4 mt-4 md:mt-0 md:ml-6">
              <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                <FaFacebookF size={20} />
              </a>
              <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                <FaTwitter size={20} />
              </a>
              <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                <FaInstagram size={20} />
              </a>
              <a href="#!" className="text-gray-200 hover:text-gray-400 transition-colors duration-200">
                <FaLinkedinIn size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}