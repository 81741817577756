import React, { useEffect, useState } from 'react';
import useScript from '../../hooks/useScript';
import { savePaymentData } from  '../../utils/payments';
import { useCheckout } from '../../contexts/CheckoutContext';
import Payment from './Payment';
import { useModal } from '../../contexts/ModalContext';
import LoadingOverlay from '../shared/LoadingOverlay';

const AddPayment = ({ onContinue }) => {
  const {
    setActiveStep,
    setThirdPartyPaymentToken,
    setFormData,
    formData,
    showCurrentPayment,
    setShowCurrentPayment,
    squarePayment,
    setSquarePayment
  } = useCheckout();
  const { openErrorModal, closeErrorModal, errorMessage, setErrorMessage } = useModal();
  const [loading, setLoading] = useState(false);

  const applicationId = process.env.REACT_APP_SQUARE_APP_ID;
  const locationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

  // Load the Square Web Payments SDK
  const sdkLoaded = useScript('https://sandbox.web.squarecdn.com/v1/square.js'); // Use production URL in production

  const initializeSquare = async () => {
    console.log('Initializing Square');
    if (sdkLoaded && window.Square && !squarePayment) {
      setLoading(true);
      try {
        // Initialize the Square payments object
        const payments = window.Square.payments(applicationId, locationId);
        const card = await payments.card();
        await card.attach('#card-container');
        setSquarePayment(card);
      } catch (error) {
        console.error("Error initializing Square:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    initializeSquare();
  }, [sdkLoaded]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAddPayment = async () => {
    if (squarePayment) {
      setLoading(true);
      try {
        const result = await squarePayment.tokenize();
        if (result.status === 'OK') {
          await handlePaymentResponse(result);
        } else {
          console.error("Payment error:", result.errors);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePaymentResponse = async (response) => {
    try {
      setThirdPartyPaymentToken(response.token);
      const paymentData = {
        ...response,
        companyName: formData.companyName,
        firstNameOnCard: formData.firstNameOnCard,
        lastNameOnCard: formData.lastNameOnCard,
      };

      const result = await savePaymentData(paymentData);
      console.log('Payment data sent successfully:', result);
      setFormData({
        cardNumber: response.details.card.last4,
        expiryDate: response.details.card.expMonth + '/' + response.details.card.expYear,
        brand: response.details.card.brand,
      });

      setActiveStep(1);
    } catch (error) {
      console.error('Failed to send payment data:', error);
      // TODO: IMPLEMENT ERROR
    }
  };

  const handleRemovePayment = () => {
    setFormData({
      companyName: '',
      firstNameOnCard: '',
      lastNameOnCard: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
    });
    setSquarePayment(null);
    setThirdPartyPaymentToken(null);
    setShowCurrentPayment(false);
  };

  useEffect(() => {
    if (!showCurrentPayment && !squarePayment) {
      initializeSquare();
    }
  }, [showCurrentPayment, squarePayment]);

  useEffect(() => {
    if (!squarePayment) {
      setShowCurrentPayment(false)
    }
  }, [squarePayment]);

  return (
    <div className="relative">
      <LoadingOverlay isLoading={loading} />

      {!showCurrentPayment ? (
        <div className="relative flex flex-col bg-[#f8f9fc] overflow-x-hidden" style={{ fontFamily: "Work Sans, Noto Sans, sans-serif" }}>
          <div className="flex h-full flex-col"> 
            <div className="flex flex-col items-center py-5 px-40">
              <div className="w-[512px] max-w-[960px]">
                <h2 className="text-lg font-bold text-[#0e121b] pb-2">Support for more payment methods coming soon</h2>
                
                {/* Company Name */}
                <div className="flex flex-wrap items-end gap-4 py-3">
                  <label className="flex flex-col flex-1">
                    {/* <p className="text-base font-medium text-[#0e121b] pb-2">Company name (optional)</p> */}
                    <input
                      type="text"
                      name="companyName"
                      className="uniform-input-style"
                      value={formData.companyName}
                      placeholder='Company name (optional)'
                      onChange={handleChange}
                    />
                  </label>
                </div>

                {/* Name on Card */}
                <div className="flex flex-wrap items-end gap-4 py-3">
                  <label className="flex flex-row flex-1 justify-between">
                    {/* <p className="text-base font-medium text-[#0e121b] pb-2">Name as it appears on card</p> */}
                    <input
                      type="text"
                      name="firstNameOnCard"
                      className="uniform-input-style inline"
                      value={formData.nameOnCard}
                      placeholder='First name on card'
                      onChange={handleChange}
                    />
                              <input
                      type="text"
                      name="lastNameOnCard"
                      className="uniform-input-style inline"
                      value={formData.nameOnCard}
                      placeholder='Last name on card'
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div id="card-container"></div>
                <h3 className='font-normal text-md font-light'>You won't be charged until the checkout step</h3>
                <div className="flex py-3">
                  <button
                    className="flex-1 h-10 px-4 bg-[#195de6] text-[#f8f9fc] text-sm font-bold rounded-xl"
                    onClick={handleAddPayment}
                  >
                    Add payment method
                  </button>
                </div>
                <p className="text-sm text-gray-600">Your payment information is encrypted and securely processed by Square. We do not store your credit card details</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Payment onRemove={handleRemovePayment} onContinue={onContinue} />
      )}
    </div>
  );
};

export default AddPayment;
