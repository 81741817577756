import React, { useState } from 'react';

const AdSizeSelector = ({ onAdSizeChange }) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleAdSizeChange = (e) => {
        const value = e.target.value;
        setSelectedValue(value);

        // Logic to update ad size and platform based on the selected option
        switch (value) {
            case 'original_size':
                // Handle the case for displaying the image in its original size
                onAdSizeChange('original', 'original');
                break;
            case 'tiktok_1080x1920':
                onAdSizeChange('aspect-[1080/1920]', 'tiktok');
                break;
            case 'instagram_1080x1080':
                onAdSizeChange('aspect-square', 'instagram');
                break;
            case 'instagram_1080x566':
                onAdSizeChange('aspect-[1080/566]', 'instagram');
                break;
            case 'instagram_1080x1350':
                onAdSizeChange('aspect-[1080/1350]', 'instagram');
                break;
            case 'instagram_reels_1080x1920':
                onAdSizeChange('aspect-[1080/1920]', 'instagram_reels');
                break;
            case 'facebook_1200x628':
                onAdSizeChange('aspect-[1200/628]', 'facebook');
                break;
            case 'facebook_1080x1080':
                onAdSizeChange('aspect-square', 'facebook');
                break;
            case 'facebook_1200x1200':
                onAdSizeChange('aspect-square', 'facebook');
                break;
            case 'facebook_1080x1920':
                onAdSizeChange('aspect-[1080/1920]', 'facebook_story');
                break;
            case 'linkedin_1200x627':
                onAdSizeChange('aspect-[1200/627]', 'linkedin');
                break;
            case 'linkedin_1080x1080':
                onAdSizeChange('aspect-square', 'linkedin');
                break;
            case 'linkedin_1080x1350':
                onAdSizeChange('aspect-[1080/1350]', 'linkedin');
                break;
            case 'linkedin_1128x376':
                onAdSizeChange('aspect-[1128/376]', 'linkedin');
                break;
            default:
                onAdSizeChange('aspect-square', 'default');
        }
    };

    return (
        <div className="">
            <select 
                id="adSizeSelect" 
                value={selectedValue} // Bind value to state
                onChange={handleAdSizeChange}
                className="border p-1 text-sm rounded mx-auto shadow-md"
            >
                {/* Default option */}
                <option value="" disabled>Ad Preview</option>

                {/* Add Original Size Option */}
                <option value="original_size">Original Size</option>
                
                <optgroup label="TikTok Ads">
                    <option value="tiktok_1080x1920">1080x1920 (Full Screen)</option>
                </optgroup>
                <optgroup label="Instagram Ads">
                    <option value="instagram_1080x1080">1080x1080 (Square)</option>
                    <option value="instagram_1080x566">1080x566 (Landscape)</option>
                    <option value="instagram_1080x1350">1080x1350 (Portrait)</option>
                </optgroup>
                <optgroup label="Instagram Reels">
                    <option value="instagram_reels_1080x1920">1080x1920 (Full Screen)</option>
                </optgroup>
                <optgroup label="Facebook Ads">
                    <option value="facebook_1200x628">1200x628 (News Feed Image)</option>
                    <option value="facebook_1080x1080">1080x1080 (Square)</option>
                    <option value="facebook_1200x1200">1200x1200 (Square)</option>
                    <option value="facebook_1080x1920">1080x1920 (Story Ad)</option>
                </optgroup>
                <optgroup label="LinkedIn Ads">
                    <option value="linkedin_1200x627">1200x627 (Sponsored Content)</option>
                    <option value="linkedin_1080x1080">1080x1080 (Square)</option>
                    <option value="linkedin_1080x1350">1080x1350 (Portrait)</option>
                    <option value="linkedin_1128x376">1128x376 (Hero Image)</option>
                </optgroup>
            </select>
        </div>
    );
};

export default AdSizeSelector;