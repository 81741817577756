// Header.jsx
import React, { useState, useRef, useEffect } from 'react';
import { IoMenu, IoClose } from 'react-icons/io5';
import { useAuth } from '../../contexts/AuthContext';
import { useModal } from '../../contexts/ModalContext';
import CustomButton from '../shared/CustomButton'; 
import logo from '../../images/logo-03.png';
import smallLogo from '../../images/logo-small.png';
import Avatar from '../shared/Avatar';
import './SubHeader.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useWelcomeModal } from '../../hooks/useWelcomeModal';
import WelcomeModal from '../modals/WelcomeModal';
import { RiCoinsFill } from "react-icons/ri";

const Header = () => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const { openLoginModal, openCouponModal, openFeedbackModal } = useModal();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef();
  const navigate = useNavigate();

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const isActivePath = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  useWelcomeModal();

  return (
    <div id="pants" className='header-wrapper'>
      <header className="header fixed top-0 w-full bg-black z-50 min-w-[500px] py-1 pr-6">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className='image-wrap'>
            <a href="/">
              <img className="hidden sm:block max-w-[200px]" src={logo} alt="imagesMarketer logo" />
              <img className="sm:hidden max-w-[50px]" src={smallLogo} alt="imagesMarketer logo" />
            </a>
          </div>

          {/* Feedback Button - Modified */}
          <button 
            className="bg-lime-600 text-white font-semibold px-4 py-2 my-2 rounded-lg flex items-center gap-2 hover:bg-lime-600 transition-colors mr-8 ml-4"
            onClick={openFeedbackModal}
          >
            Give&nbsp;Feedback! <RiCoinsFill className="text-lg" />
          </button>

          {/* Desktop Navigation */}
          <nav className="desktop-menu lg:flex space-x-6 mr-8">
            <a href="/create" className={`hover:bg-gray-600 p-3 rounded text-white transition-all ${isActivePath('/create')}`}>
              Create
            </a>
            <a href="/how-it-works" className={`hover:bg-gray-600 p-3 rounded text-white transition-all ${isActivePath('/how-it-works')}`}>
              How it Works
            </a>
            <div className="relative group inline-block">
              <a href="/credits" className={`hover:bg-gray-600 p-3 rounded text-white transition-all inline-block ${isActivePath('/credits')}`}>
                Get Credits
              </a>
              <div className="absolute left-0 mt-1 w-48 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                <div className="bg-gray-700 rounded-lg shadow-lg py-2">
                  <button
                    onClick={openCouponModal}
                    className="w-full text-left px-4 py-2 text-white hover:bg-gray-600 transition-colors"
                  >
                    I have a coupon code!
                  </button>
                </div>
              </div>
            </div>
            {!isLoggedIn && (
            <a 
              href="#" 
              className="hover:bg-gray-600 p-3 rounded text-white transition-all"
              onClick={(e) => {
                e.preventDefault();
                openLoginModal();
              }}
            >
              Log in
            </a>
            )}
          </nav>
          {/* Desktop "Try Free Now" Button */}
          {!isLoggedIn && (
            <div className="hidden lg:block">
              <CustomButton className="text-xl py-1 text-white" onClick={() => navigate('/create')}>Try For Free Now</CustomButton>
            </div>
          )}
          {isLoggedIn && (
            <Avatar />
          )}
          {/* Mobile Menu Button */}
          <div className="mobile-menu">
            <button
              onClick={handleToggleMobileMenu}
              className="text-white focus:outline-none"
              aria-label="Toggle Menu"
              aria-expanded={isMobileMenuOpen}
              aria-controls="mobile-menu"
            >
              {isMobileMenuOpen ? (
                <IoClose className="h-8 w-8 text-3xl" /> // Increased from h-6 w-6 text-2xl
              ) : (
                <IoMenu className="h-8 w-8 text-3xl" /> // Increased from h-6 w-6 text-2xl
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        <div
          ref={menuRef}
          id="mobile-menu"
          className={`lg:hidden pl-6 pb-4 bg-gray-700 rounded-b-lg shadow-md shadow-gray-400 transform transition-all duration-300 ease-in-out ${
            isMobileMenuOpen ? 'max-h-96 opacity-100 translate-y-0 mb-0' : 'max-h-0 opacity-0 -translate-y-2 -mb-5'
          } overflow-hidden`}
        >
          <nav className="flex flex-col space-y-1 text-center text-xl">
            <a href="/create" className={`hover:bg-gray-600 p-3 rounded text-white transition-all ${isActivePath('/create')}`} onClick={() => setIsMobileMenuOpen(false)}>
              Create
            </a>
            <a href="/how-it-works" className={`hover:bg-gray-600 p-3 rounded text-white transition-all ${isActivePath('/how-it-works')}`} onClick={() => setIsMobileMenuOpen(false)}>
              How it Works
            </a>
            <a href="/credits" className={`hover:bg-gray-600 p-3 rounded text-white transition-all ${isActivePath('/credits')}`} onClick={() => setIsMobileMenuOpen(false)}>
              Get Credits
            </a>
            <a href="#" className="hover:bg-gray-600 p-3 rounded text-white transition-all" onClick={() => {
              setIsMobileMenuOpen(false);
              openCouponModal();
            }}>
              I have a coupon code!
            </a>
            {!isLoggedIn && (
            <a
              href="#"
              className="hover:bg-gray-600 p-3 rounded text-white transition-all"
              onClick={(e) => {
                e.preventDefault();
                openLoginModal();
              }}
            >
              Log in
            </a>
            )}
          </nav>
          {!isLoggedIn && (
            <div className="mt-4">
              <CustomButton 
                 onClick={() => navigate('/create')}
                className="w-full text-xl py-3"
              >
                Try For Free Now?????
              </CustomButton>
            </div>
          )}
        </div>
      </header>
      <WelcomeModal />
    </div>
  );
};

export default Header;