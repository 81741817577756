import React from 'react';

const CustomButton = ({ children, onClick, className, ...props }) => {
  return (
    <button
      onClick={onClick}
      className={`border-pink-600 border-2 flex justify-center items-center justify-items-center px-4 py-2 rounded hover:bg-pink-500 transition duration-300  ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default CustomButton;