import { useEffect, useState } from 'react';

function useScript(src) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Check if the script is already added
    if (document.querySelector(`script[src="${src}"]`)) {
      setLoaded(true);
      return;
    }

    // Create script element
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    
    // Set the loaded state to true once the script loads successfully
    script.onload = () => {
      setLoaded(true);
      console.log(`${src} loaded`);
    };

    // Error handling if the script fails to load
    script.onerror = () => {
      console.error(`Error loading ${src}`);
      setLoaded(false);
    };

    // Append script to the document body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
      setLoaded(false);
    };
  }, [src]);

  return loaded;
}

export default useScript;