import React from 'react';
import { ReactTyped as Typed } from 'react-typed';

const TypewriterEffect = ({ className }) => {
  const words = [
    "Instagram Ads",
    "TikTok Reels",
    "Facebook Ads",
    "Google Ads",
    "LinkedIn Ads",
    "Pinterest Pins",
    "Twitter Ads",
    "YouTube Ads"
  ];

  return (
    <h1 className={`${className} mt-[100px] md:mt-[120px] lg:mt-[150px] text-4xl md:text-6xl lg:text-7xl xl:text-8xl xl:text-center font-bold text-green-300 md:mb-6 lg:max-w-[90%] 2xl:max-w-[70%] lg:leading-tight`}>
      Generate high converting AI images for your<span className='lg:block'>{' '}</span>
      <Typed
        strings={words}
        typeSpeed={40}
        backSpeed={30}
        backDelay={2400}
        loop
        className="text-green-500"
      />
    </h1>
  );
};

export default TypewriterEffect;