import React from 'react';

const UserMessageModal = ({ message, type, onClose, errorCode }) => {
    // Map error numbers to titles and messages
    const errorDetails = {
        400: { title: "Bad Request", message: "Your request was invalid. Please check and try again." },
        401: { title: "Unauthorized", message: "You are not authorized to perform this action." },
        403: { title: "Forbidden", message: "Access to this resource is forbidden." },
        404: { title: "Not Found", message: "The requested resource could not be found." },
        500: { title: "Server Error", message: "An internal server error occurred. Please try again later." },
        503: { title: "Service Unavailable", message: "The service is currently unavailable. Please try again later." },
    };

    // Default titles for each message type
    const defaultTitles = {
        error: "Oopsy! That didn't work!",
        warning: "Woah, careful!",
        info: "FYI: Here's some information",
        success: "Success! It worked!",
    };

    // Use default or custom title/message based on errorCode
    const errorTitle = errorCode && errorDetails[errorCode] ? errorDetails[errorCode].title : defaultTitles[type] || "Something happened";

    // Handle restricted words error
    let errorMessage = message;
    if (message === "restricted_words") {
        errorMessage = "Your input contains restricted content. Please adjust your text and try again.";
    } else if (errorCode && errorDetails[errorCode]) {
        errorMessage = errorDetails[errorCode].message;
    }

    // Map message types to styles
    const messageStyles = {
        error: 'bg-white text-black text-md',
        warning: 'bg-yellow-500 text-white font-semibold text-md',
        info: 'bg-blue-500 text-white font-semibold text-md',
        success: 'bg-green-500 text-white font-semibold text-md',
    };

    // Use default style if type is not provided
    const style = messageStyles[type] || messageStyles.info;

    // Fallback for onClose if not provided
    const handleClose = onClose || (() => console.warn("onClose function is not provided"));

    // Only show the message if there is one
    if (!errorMessage) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className={`p-6 rounded-lg shadow-lg ${style} relative max-w-[450px]`}>
                <h2 className="text-xl mb-2 font-bold">{errorTitle}</h2>
                <button
                    className="absolute top-2 right-2 text-black text-xl"
                    onClick={handleClose}
                >
                    &times;
                </button>
                <p className="mb-4">{errorMessage}</p>
                {type === 'error' ? (
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded-md float-right"
                        onClick={handleClose}
                    >
                        Close and Try Again
                    </button>
                ) : (
                    <button
                        className="bg-gray-500 text-white px-4 py-2 rounded-md"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                )}
            </div>
        </div>
    );
};

export default UserMessageModal;