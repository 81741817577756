import React from 'react';
import { GoComment, GoThumbsup, GoShareAndroid, GoPerson } from "react-icons/go";
import { IoEarth, IoPaperPlaneOutline, IoMusicalNotes } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io"; 
import { FaRegComment, FaHeart, FaRegHeart, FaRegCommentDots, FaRegBookmark, FaCommentDots, FaTiktok } from "react-icons/fa";
import { BsHandThumbsUp } from "react-icons/bs";
import { RiShareForwardLine, RiShareForwardFill, RiHome4Fill } from "react-icons/ri";
import { BiCommentMinus } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import ImageZoom from './ImageZoom';

const PostMockup = ({ platform, imageUrl, adSize }) => {
    // Log the incoming props
    // console.log("Platform:", platform);
    // console.log("AdSize:", adSize);

    // Verificar si es un anuncio de Story Ads basado en `platform`
    if (platform && platform.trim().toLowerCase() === 'facebook_story') {
        console.log("Rendering facebook_story mockup");

        return (
          <>
            <div className="bg-black relative max-w-lg mx-auto border border-gray-300 rounded-lg transition-shadow duration-300 shadow-md hover:shadow-2xl mb-10">
                {/* Story Header */}
                <div className="px-4 pt-4 absolute text-white w-full">
                    <div className='w-full h-1 rounded-lg mb-2 bg-gray-400'>
                        <div className='w-72 h-full rounded-lg bg-white'></div>
                    </div>
                    <div className='flex items-center space-x-3 '>
                      <img
                          className="w-10 h-10 rounded-full"
                          src="https://via.placeholder.com/150"
                          alt="Page Avatar"
                          />
                    <div>
                        <p className="font-semibold">Page Name</p>
                        <div className="flex items-center space-x-1">
                            <p className="text-sm text-white">Sponsored</p>
                            <IoEarth className="text-white" />
                        </div>
                    </div>
                    </div>
                </div>

                {/* Story Image */}
                    <div className="relative w-full aspect-[9/16] overflow-hidden flex items-center justify-center mx-auto">
                        <ImageZoom imageUrl={imageUrl} />
                    </div>

                {/* Story Call to Action */}
                <div className='absolute w-full bottom-0'>
                    <div className="flex justify-center mt-4">
                        <button className="bg-blue-500 text-white font-semibold py-2 px-6 rounded-full">
                            Swipe Up
                        </button>
                    </div>

                    {/* Story Footer */}
                    <div className="text-center text-white text-sm mt-2 pb-4">
                        Your Website Title
                    </div>
                </div>
            </div>
          </>
        );
    }

    if (platform && platform.trim().toLowerCase() === 'original') {
        return (
            <div className="bg-white max-w-4xl mx-auto border border-gray-300 rounded-lg transition-shadow duration-300 shadow-md hover:shadow-2xl mb-10">
                <div className={`w-full overflow-hidden flex items-center justify-center mx-auto`}>
                    <ImageZoom imageUrl={imageUrl} />
                </div>
            </div>
        );
    }

    // Diseño regular para otros tamaños de anuncios de Facebook
    if (platform && platform.trim().toLowerCase() === 'facebook') {
        return (
            <div className="bg-white max-w-lg mx-auto border border-gray-300 rounded-lg transition-shadow duration-300 shadow-md hover:shadow-2xl mb-10">
                {/* Header */}
                <div className="flex items-center space-x-3 px-4 pt-4">
                  <img
                    className="w-10 h-10 rounded-full"
                    src="https://via.placeholder.com/150"
                    alt="Page Avatar"
                  />
                  <div>
                    <p className="font-semibold">Page Name</p>
                    <div className="flex items-center space-x-1">
                      <p className="text-sm text-gray-500">Sponsored</p>
                      <IoEarth className="text-gray-500" />
                    </div>
                  </div>
                </div>

                {/* Caption */}
                <div className="mt-3 px-4">
                    <p className="text-gray-700">
                        Ad copy will be displayed here. It can be a promotional text or a description of your ad.
                    </p>
                </div>

                {/* Image */}
                <div className="mt-3">
                    <div className={`relative w-full ${adSize} overflow-hidden flex items-center justify-center mx-auto`}>
                        <ImageZoom imageUrl={imageUrl} />
                    </div>
                </div>

                {/* Link */}
                <div className='bg-gray-100 p-4 flex justify-between items-center'>
                    <div>
                        <p className='text-xs'>WEBSITE.COM</p>
                        <p className='text-lg font-semibold'>Your Website Title</p>
                    </div>
                    <button className='bg-gray-300 rounded font-semibold p-2 px-4'>
                        Shop now
                    </button>
                </div>

                {/* Reaction icons */}
                <div className="px-4 pb-4">
                    <div className="flex justify-between items-center pt-3 mb-3">
                        <div className="flex items-center text-gray-500">
                            <img src="../facebook-like.svg" alt='' className='-mr-1' />
                            <img src="../facebook-surprise.svg" alt='' className='-mr-1' />
                            <img src="../facebook-laugh.svg" alt=''  className='mr-1'/>
                            <p>Eric Liang and 120 others</p>
                        </div>
                        <div className="text-gray-500 text-sm">54 Comments</div>
                    </div>

                    <div className="flex justify-between items-center border-t border-gray-300 pt-3">
                        <div className="flex items-center space-x-1 text-gray-500">
                            <i className="far fa-thumbs-up"></i>
                            <span className="flex items-center space-x-1">
                                <GoThumbsup /> <p>Like</p>
                            </span>
                            <i className="far fa-comment-dots ml-4"></i>
                            <span className="flex items-center space-x-1">
                                <GoComment /> <p>Comment</p>
                            </span>
                            <i className="far fa-share-square ml-4"></i>
                            <span className="flex items-center space-x-1">
                                <GoShareAndroid /> <p>Share</p>
                            </span>
                        </div>
                        <div className="text-gray-500 text-sm">3 hours ago</div>
                    </div>
                </div>
            </div>
        );
    }


    if (platform && platform.trim().toLowerCase() === 'instagram') {
        return (
            <div className="bg-white max-w-lg mx-auto border border-gray-300 rounded-lg transition-shadow duration-300 shadow-md hover:shadow-2xl mb-10">
                {/* Header */}
                <div className="flex items-center space-x-3 px-4 pt-4">
                    <img
                        className="w-10 h-10 rounded-full"
                        src="https://via.placeholder.com/150"
                        alt="Profile Avatar"
                    />
                    <div>
                        <p className="font-semibold">instagram_user</p>
                        <p className="text-sm text-gray-500">Sponsored</p>
                    </div>
                    <div className="ml-auto">
                        <i className="fas fa-ellipsis-h text-gray-500"></i>
                    </div>
                </div>
    
                {/* Image */}
                <div className="mt-3">
                    <div className={`relative w-full ${adSize} overflow-hidden flex items-center justify-center mx-auto`}>
                        <ImageZoom imageUrl={imageUrl} />
                    </div>
                </div>
    
                {/* Learn More */}
                <div className="px-4 pt-3 border-b pb-3">
                    <p className="text-md">Learn More <IoIosArrowForward className='float-right text-2xl'/></p>
                </div>
    
                {/* Number of likes */}
                <div className="mt-1 px-4">
                    <p>
                        <span className="font-semibold">15 Likes</span>
                    </p>
                </div>

                {/* Icons */}
                <div className="my-2 px-4 text-2xl flex items-center justify-between">
                    <div className="flex space-x-2">
                        <FaRegHeart />
                        <FaRegComment />
                        <IoPaperPlaneOutline />
                    </div>
                    <FaRegBookmark className="ml-auto" />
                </div>
              
                {/* Caption */}
                <div className="mt-1 px-4 pb-5">
                    <p>
                        <span className="font-semibold">instagram_user</span> This is a sample Instagram caption for the mockup post, it can be anything.
                    </p>
                </div>

            </div>
        );
    }
    
    if (platform && platform.trim().toLowerCase() === 'instagram_reels') {
        return (
            <div className="bg-black w-full max-w-lg h-screen mx-auto relative overflow-hidden rounded-lg transition-shadow duration-300 shadow-md hover:shadow-2xl mb-10">

            <div 
            className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-60 z-10"
            style={{ backgroundSize: '20% 80%' }}>
            </div>

                {/* Image */}
                <div className="relative w-full h-full overflow-hidden">
                    <ImageZoom imageUrl={imageUrl} />
                </div>
    
                {/* Overlay - User Info */}
                <div className="absolute bottom-36 left-4 text-white space-y-2 z-10">
                    <div className="flex items-center space-x-3">
                        <img
                            className="w-10 h-10 rounded-full"
                            src="https://via.placeholder.com/150"
                            alt="Profile Avatar"
                        />
                        <div>
                            <p className="font-semibold">instagram_user</p>
                        </div>
                    </div>
                </div>

                {/* Button */}
                <div className='bg-blue-500 bottom-20 absolute text-white w-80 z-10 left-4 py-3 px-4 rounded-md'>
                    <p>Learn More</p>
                </div>


    
                {/* Caption */}
                <div className="absolute bottom-5 left-4 text-white z-10">
                    <p>
                        <span className="font-semibold">instagram_user</span> This is the caption of the Reel, describing the content in a brief and engaging way.
                    </p>
                </div>
    
                {/* Engagement - Icons */}
                <div className="absolute bottom-32 right-4 text-white text-2xl flex flex-col space-y-4 z-10">
                    <div className="text-center">
                        <FaRegHeart className='text-3xl mb-1' />
                        <p className="text-xs">1.2k</p>
                    </div>
                    <div className="text-center">
                        <FaRegComment className='text-3xl mb-1' />
                        <p className="text-xs">567</p>
                    </div>
                    <div className="text-center">
                        <IoPaperPlaneOutline className='text-3xl mb-1' />
                        <p className="text-xs">Share</p>
                    </div>
                    <div className="text-center">
                        <FaRegBookmark className='text-3xl mb-1' />
                        <p className="text-xs">Save</p>
                    </div>
                </div>
            </div>
        );
    }
    
    
    if (platform && platform.trim().toLowerCase() === 'linkedin') {
        return (
            <div className="bg-white max-w-lg mx-auto border border-gray-300 rounded-lg transition-shadow duration-300 shadow-md hover:shadow-2xl mb-10">
                {/* Header */}
                <div className="flex items-center space-x-3 px-4 pt-4">
                    <img
                        className="w-10 h-10"
                        src="https://via.placeholder.com/150"
                        alt="LinkedIn Profile Avatar"
                    />
                    <div>
                        <p className="font-semibold">Company Name</p>
                        <div className="flex items-center space-x-1">
                            <p className="text-sm text-gray-500">Promoted</p>
                        </div>
                    </div>
                </div>
    
                {/* Caption */}
                <div className="mt-1 px-4">
                    <p className="text-gray-700 leading-5">
                        This is a caption that will display on the LinkedIn mockup post. It can be any promotional text or description of your ad.
                    </p>
                </div>
    
                {/* Image */}
                <div className="mt-3">
                    <div className={`relative w-full ${adSize} overflow-hidden flex items-center justify-center mx-auto`}>
                        <ImageZoom imageUrl={imageUrl} />
                    </div>
                </div>
    
                {/* Call to action */}
                <div className='p-4 flex justify-between items-center bg-blue-100'>
                    <div>
                        <p className='text-md pr-10 font-semibold leading-5'>Here you will write about your business next to a button</p>
                    </div>
                    <button className='border-blue-600 border rounded-full text-blue-600 font-semibold p-1 px-4 min-w-[140px]'>
                        Learn More
                    </button>
                </div>

                <div className='w-full px-4 p-3'>
                    <p className='float-left'>
                        <img src="./linkedin.png" className='w-20 float-left -mt-1' alt="" />
                        <span className='text-sm ml-2 absolute text-gray-500'>256</span>
                    </p>
                    <p className='text-sm text-gray-500 text-right'>87 Comments</p>
                </div>
    
                {/* Action Links: Like, Comment, Share */}
                <div className="px-4 py-3 flex justify-between items-center border-t border-gray-300">
                    {/* Like */}
                    <div className="flex items-center space-x-1 text-gray-500">
                    <BsHandThumbsUp className='transform -scale-x-100' />
                        <p className="text-sm font-semibold cursor-pointer">Like</p>
                    </div>
    
                    {/* Comment */}
                    <div className="flex items-center space-x-1 text-gray-500">
                        <FaRegCommentDots />
                        <p className="text-sm font-semibold cursor-pointer">Comment</p>
                    </div>
    
                    {/* Share */}
                    <div className="flex items-center space-x-1 text-gray-500">
                        <RiShareForwardLine />
                        <p className="text-sm font-semibold cursor-pointer">Share</p>
                    </div>
                </div>
            </div>
        );
    }


    if (platform && platform.trim().toLowerCase() === 'tiktok') {
        return (
            <div className="bg-black w-full max-w-md min-h-[500px] h-[95vh] mx-auto relative overflow-hidden rounded-lg transition-shadow duration-300 shadow-md hover:shadow-2xl mb-10">

            <div 
            className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-60 z-10"
            style={{ backgroundSize: '20% 80%' }}>
            </div>

                {/* Following/For You Tabs */}
                <div className="absolute top-4 w-full text-center z-10">
                    <div className="text-white flex justify-center items-center space-x-4">
                        <p className="text-gray-400 relative">Following
                        <span className='absolute text-xs text-white bg-red-600 w-2 h-2 flex items-center justify-center rounded-full font-mono top-1.5 -right-2'></span>
                        </p>
                        <p className="text-white font-semibold">For You</p>
                    </div>
                </div>
    
                {/* Video/Image */}
                <div className="relative w-full h-full overflow-hidden z-auto">
                    <ImageZoom imageUrl={imageUrl} />
                </div>
    
                {/* Overlay - User Info */}
                <div className="absolute bottom-32 left-4 text-white space-y-1 z-10">
                    <p className="font-semibold">@Brandname</p>
                    <p className='text-sm mr-20'>This is caption that will display on the final mockup. You can write anything.
                        <span className="bg-gray-500 px-2 py-1 rounded-full text-xs ml-2">Sponsored</span>                    
                    </p>
                    <p className="flex items-center"><IoMusicalNotes /> Cheryl - @Yung Gravy</p>
                </div>

                {/* Call to action button */}
                <div className='absolute bottom-20 left-4 text-white z-10 w-80'>
                    <button className='bg-red-500 py-2 px-4 rounded-md flex justify-center items-center w-full'>
                        Learn more <IoIosArrowForward className="ml-2" />
                    </button>
                </div>    
    
                {/* Engagement - Icons (Aligning to the right side) */}
                <div className="absolute bottom-36 md:bottom-20 right-4 text-white text-2xl flex flex-col items-center space-y-4 z-10">
                    <div className="text-center">
                        <div className="rounded-full bg-purple-500 border-2 border-white p-2">
                            <FaTiktok />
                        </div>
                    </div>
                    <div className="text-center">
                        <FaHeart className='text-4xl' />
                        <p className="text-xs">21.5k</p>
                    </div>
                    <div className="text-center">
                        <FaCommentDots className='text-4xl' />
                        <p className="text-xs">125</p>
                    </div>
                    <div className="text-center">
                        <RiShareForwardFill className='text-4xl' />
                        <p className="text-xs">321</p>
                    </div>
                    <div className="text-center">
                    <IoMusicalNotes className='absolute -ml-5 text-lg'/>
                        <div className="rounded-full bg-purple-500 border-8 border-black p-1.5">
                            <FaTiktok className='text-sm'/>
                        </div>
                    </div>

                </div>
    
                {/* Bottom Navbar */}
                <div className="absolute bottom-0 pb-9 pt-8 w-full h-16 bg-black flex justify-around items-center text-gray-400 z-20">
                    <div className="flex flex-col items-center">
                        <RiHome4Fill className='text-2xl'  />
                        <p className="text-xs">Home</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <FiSearch className='text-2xl' />
                        <p className="text-xs">Discover</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="w-22 h-22 flex justify-center items-center transform scale-150">
                        <svg width="41" height="25" viewBox="0 0 41 25" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.875" width="32" height="25" rx="7" fill="#65D2E9"></rect> <rect x="8.125" width="32" height="25" rx="7" fill="#E6436D"></rect> <rect x="4" width="32" height="25" rx="7" fill="white"></rect> <path d="M19.0276 6C18.5328 6 18.1316 6.40118 18.1316 6.89606V11.1316H13.8961C13.4012 11.1316 13 11.5328 13 12.0276V12.9724C13 13.4672 13.4012 13.8684 13.8961 13.8684H18.1316V18.1039C18.1316 18.5988 18.5328 19 19.0276 19H19.9724C20.4672 19 20.8684 18.5988 20.8684 18.1039V13.8684H25.1039C25.5988 13.8684 26 13.4672 26 12.9724V12.0276C26 11.5328 25.5988 11.1316 25.1039 11.1316H20.8684V6.89606C20.8684 6.40118 20.4672 6 19.9724 6H19.0276Z" fill="black"></path></svg>
                        </div>
                    </div>
                    <div className="flex flex-col items-center relative">
                    <span className='absolute text-xs text-white bg-red-600 w-5 h-5 flex items-center justify-center rounded-full font-mono -top-2 -right-1'>
                    9
                    </span>
                        <BiCommentMinus className='text-2xl' />
                        <p className="text-xs">Inbox</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <GoPerson className='text-2xl' />
                        <p className="text-xs">Me</p>
                    </div>
                </div>
            </div>
        );
    }
    

    return <div>Mockup for {platform} is not yet available.</div>;
};

export default PostMockup;