// src/components/Testimonials.js
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

export default function Testimonials() {
  return (
    <section className="w-full flex flex-col items-center justify-center">
      {/* Center bit for mobile devices */}
      <div className="border-l border-gray-600 mt-0 mb-0 w-[1%] h-20 md:hidden"></div>

      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-0">
          {/* Left Column */}
          <div className="border md:mt-0 p-6 md:border-r md:border-l-0 md:border-t-0 md:border-b-0 border-gray-600 md:pr-8 text-white md:pb-20 md:pt-24">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 max-w-[400px]">
              The Future of Advertising is AI-Driven
            </h2>
            <p className="text-lg md:text-2xl mb-4 text-gray-400">
            AI is transforming marketing by enabling the creation of captivating, platform-ready ads with just one click, boosting engagement and keeping campaigns competitive.
            </p>
          </div>

          {/* Center bit for mobile */}
          <div className="border-r border-b border-gray-600 mt-0 mb-0 w-[50%] h-20 md:hidden"></div>

          {/* Right Column */}
          <div className="flex flex-col justify-between text-white pt-10 md:pb-10 md:pt-20 border-l border-gray-600 md:border-none">
            {/* First Row */}
            <div className="space-y-2 border-b border-gray-600 pb-14 pl-10">
              <h4 className="text-2xl font-semibold">Forbes</h4>
              <blockquote className="text-xl text-gray-300 relative pl-4">
                <span className="text-pink-400 text-4xl absolute left-0 top-0">“</span>
                Companies using AI for image creation have seen a 20% increase in ad performance.
              </blockquote>
              <p>
                <a
                  href="https://www.forbes.com/sites/forbestechcouncil/2021/06/30/the-future-of-ai-in-digital-marketing/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-pink-500 font-bold hover:underline flex items-center ml-4"
                >
                  Read Article <FaArrowRight className="ml-1" />
                </a>
              </p>
            </div>

            {/* Second Row */}
            <div className="md:pt-8 space-y-2 md:pl-10 border-r md:border-r-0 border-gray-400 -ml-2 md:-ml-0 bg-gray-950 pb-10 text-right pr-9 md:pr-0 md:text-left">
              <h4 className="text-2xl font-semibold mt-12 md:mt-0">Marketing Tech News</h4>
              <blockquote className="text-xl text-gray-300 relative md:ml-3 md:pl-0">
                <span className="text-pink-400 text-4xl absolute -ml-4 md:ml-0 top-0 md:-left-4">“</span>
                CAI-generated visuals are revolutionizing advertising efficiency.
              </blockquote>
              <p className='float-right lg:ml-4 lg:float-none'>
                <a
                  href="https://martechseries.com/predictive-ai/ai-platforms-machine-learning/ai-image-generation-transforming-digital-marketing/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-pink-500 font-bold hover:underline flex items-center"
                >
                  Read Report <FaArrowRight className="ml-1" />
                </a>
              </p>
            </div>
          </div>

          {/* Center bit for mobile */}
          <div className="border-r border-gray-400 mt-0 mb-0 w-[50%] h-20 md:hidden border-b rotate-180 translate-x-[100%]"></div>
        </div>
      </div>
    </section>
  );
}