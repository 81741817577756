import { useContext, useEffect } from 'react';
import { useCheckout } from '../../../contexts/CheckoutContext';
import StarterCard from './StarterCard';

export default function StarterCartItem() {
  const { setCheckoutTotal, setCreditCount, quantity, setQuantity, setFrequencyText, frequencyText } = useCheckout();
  const ITEM_PRICE = 7; // in US dollars
  const CREDITS_PER_ITEM = 50;
  const FREQUENCY_TEXT = 'One time purchase';

  useEffect(() => {
    setCheckoutTotal(1 * ITEM_PRICE);
    setCreditCount(1 * CREDITS_PER_ITEM);
    setQuantity(1);
    setFrequencyText(FREQUENCY_TEXT);
  }, []);

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setCheckoutTotal(newQuantity * ITEM_PRICE);
    setCreditCount(newQuantity * CREDITS_PER_ITEM);
    setQuantity(newQuantity);
  };

  return (
    <>
      <div className="w-[50%]">
        <StarterCard />
      </div>
      <div className="w-[50%] p-0">
        <table className="w-full mb-6 text-lg mx-4 text-black">
          <tbody>
            <tr className="border-b">
              <td className="py-2">Quantity</td>
              <td className="py-2">
                <select 
                  className="border rounded px-2 py-1"
                  value={quantity}
                  onChange={handleQuantityChange}
                >
                  {[1,2,3,4,5,6,7].map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Frequency</td>
              <td className="py-2">{frequencyText}</td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Tax</td>
              <td className="py-2">$0</td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Credits</td>
              <td className="py-2">{quantity * CREDITS_PER_ITEM} credits</td>
            </tr>
            <tr className="border-b font-bold">
              <td className="py-2">Total</td>
              <td className="py-2">${quantity * ITEM_PRICE}.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
} 