// src/components/PriceMatrix.js
/**
 * PriceMatrix Component
 *
 * @component
 * @description This component renders the pricing structure with the three different plans: Starter Plan, Pro Plan, and Unlimited
 * The component includes interactive buttons and dynamic elements to highlight the value of each plan. Right now, the buttons go 
 * nowhere but we need to update that.
 * by calloing <Pricing /> we get it anywhere (right now, HomePage.js)
 *
 * @returns {JSX.Element} A section displaying the pricing plans and their features.
 */

import React from 'react';
import { RiCoinsFill } from "react-icons/ri";
import LoginButton from '../LoginButton';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import StarterCard from './plans/StarterCard';
import ProCard from './plans/ProCard';
import UnlimitedCard from './plans/UnlimitedCard';
import { useModal } from '../../contexts/ModalContext';

export default function PriceMatrix() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { openCouponModal } = useModal();

  return (
    <section className="bg-gray-100 px-2 md:px-8 relative">
      <div 
        className="absolute -left-[85px] top-[50px] bg-gradient-to-r from-pink-500 to-fuchsia-500 text-white py-2 px-20 transform -rotate-45 shadow-lg cursor-pointer hover:from-pink-600 hover:to-fuchsia-600 transition-all duration-300"
        onClick={openCouponModal}
      >
        <span className="font-bold">I have a coupon code!</span>
      </div>

      <div className="2xl:max-w-[90%] mx-auto my-16">
        {!isLoggedIn && (
          <>
            <h2 className="text-5xl md:text-7xl font-extrabold text-center mb-2 text-gray-900">
              Start Right Now For Free
            </h2>
            <p className="text-center mb-12 text-2xl max-w-[80%] mx-auto my-4 text-gray-700">
              Get <RiCoinsFill size={25} className='inline-block mt-[-5px]' />50 credits for free just for signing up! It's quick and easy–no credit card required. Just click the button below and sign in with Google (more sign-in options coming soon).
              <LoginButton 
                className="px-8 py-3 inline text-2xl mx-auto text-black hover:text-white mt-8 font-bold"
                referrer="pricing_hero"
                onClick={() => navigate('/create')}
              >
                Try Now For Free
              </LoginButton>
            </p>
          </>
        )}

        <h2 className="text-5xl md:text-7xl font-extrabold text-center mb-2 mt-20 text-gray-900">Three Flavors, Three Prices</h2>
        <p className="text-center mb-12 text-2xl max-w-[80%] mx-auto my-4 text-gray-700">
          Straightforward Pricing. No Data Sales. No Hidden Fees. Just high-quality, AI-powered image generation whenever you need it.
        </p>
        <div className="justify-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            {/* Starter Plan */}
            <div
              className={`bg-white rounded-2xl p-4 md:p-6 md:px-10 shadow-xl hover:shadow-2xl hover:shadow-gray-500 scale-95 hover:scale-100 transition-all duration-300 text-center border-4 border-transparent`}
            >
              <h3 className="text-4xl font-bold text-gray-800 py-4">Starter Plan</h3>
              <StarterCard />

              <div className="space-y-5 mt-6 text-lg">
                <h4 className="text-pink-600 text-xl font-semibold mt-[2em]">
                  images.fords.ai Starter Plan Includes:
                </h4>

                {/* Feature 1 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">50 Credits</h4>
                  <p className="text-gray-700 text-md">
                    Make a one-time purchase of 50 credits to generate images or use inpainting features.
                  </p>
                </div>

                {/* Feature 2 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Full commercial rights</h4>
                  <p className="text-gray-700 text-md">
                    Your images, your business. Use them any way you want, no strings attached.
                  </p>
                </div>

                {/* Feature 3 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Privacy, Guaranteed</h4>
                  <p className="text-gray-700 text-md">
                    Your data stays private. Confidentiality is everything to us—no tracking, no nonsense.
                  </p>
                </div>

                {/* Feature 5 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Free Credits for Feedback</h4>
                  <p className="text-gray-700 text-md">
                    Earn additional credits by giving us feedback during our Beta round.
                  </p>
                </div>

                <button
                    type="button"
                    onClick={() => navigate('/purchase/starter')}
                    className="w-full px-7 py-4 text-2xl rounded-md hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700 bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white"
                >
                  Get the Starter Plan Now
                </button>
              </div>
            </div>

            {/* Pro Plan */}
            <div
              className={`bg-white rounded-2xl p-4 md:p-6 md:px-10 shadow-xl hover:shadow-2xl hover:shadow-gray-500 scale-95 hover:scale-100 transition-all duration-300 text-center border-4 border-transparent`}
            >
              <h3 className="text-4xl font-bold text-gray-800 py-4">Pro Plan</h3>
              <ProCard />

              <div className="space-y-5 mt-6 text-lg">
                <h4 className="text-pink-600 text-xl font-semibold mt-[2em]">
                  images.fords.ai Pro Includes:
                </h4>

                {/* Feature 1 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">90 Credits per Month</h4>
                  <p className="text-gray-700 text-md">
                    Subscribe and receive 90 credits each month for image generation and editing.
                  </p>
                </div>

                {/* Feature 2 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Full commercial rights</h4>
                  <p className="text-gray-700 text-md">
                    Your images, your business. Use them any way you want, no strings attached.
                  </p>
                </div>

                {/* Feature 3 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Privacy, Guaranteed</h4>
                  <p className="text-gray-700 text-md">
                    Your data stays private. Confidentiality is everything to us—no tracking, no nonsense.
                  </p>
                </div>

                {/* Feature 4 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">24/7 Customer Support</h4>
                  <p className="text-gray-700 text-md">
                    Our dedicated team is available around the clock via email, ready to assist you whenever you need it.
                  </p>
                </div>

                {/* Feature 5 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Free Credits for Feedback</h4>
                  <p className="text-gray-700 text-md">
                    Earn additional credits by giving us feedback during our Beta round.
                  </p>
                </div>

                <button
                  type="submit"
                  onClick={() => navigate('/purchase/pro')}
                  className="w-full px-7 py-4 text-2xl rounded-md hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700 bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white"
                >
                  Get the Pro Plan Now
                </button>
              </div>
            </div>

            {/* Unlimited Plan */}
            <div
              className={`bg-white rounded-2xl p-4 md:p-6 md:px-10 shadow-xl hover:shadow-2xl hover:shadow-gray-500 scale-95 hover:scale-100 transition-all duration-300 text-center border-4 border-pink-500`}
            >
              <div className="bg-pink-500 text-sm rounded-2xl text-white font-semibold py-2 px-4 uppercase inline absolute -top-5 left-1/2 transform -translate-x-1/2">
                Best Value
              </div>
              <h3 className="text-4xl font-bold text-gray-800 py-4">Unlimited Plan</h3>
              <UnlimitedCard />

              <div className="space-y-5 mt-6 text-lg">
                <h4 className="text-pink-600 text-xl font-semibold mt-[2em]">
                  images.fords.ai Unlimited Includes:
                </h4>

                {/* Feature 1 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Unlimited Credits</h4>
                  <p className="text-gray-700 text-md">
                    Enjoy three months of unlimited credits for all your image generation and editing needs.
                  </p>
                </div>

                {/* Feature 2 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Everything in the Pro Plan</h4>
                  <p className="text-gray-700 text-md">
                    All the features from the Pro Plan are included, plus more.
                  </p>
                </div>

                {/* Feature 3 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Early access to new features</h4>
                  <p className="text-gray-700 text-md">
                    Be the first to try out the latest features before anyone else.
                  </p>
                </div>

                {/* Feature 4 */}
                <div className="rounded-lg p-3 md:p-4 bg-gray-50">
                  <h4 className="font-semibold text-black">Dedicated account manager</h4>
                  <p className="text-gray-700 text-md">
                    Get personalized support from your dedicated account manager, tailored to your needs.
                  </p>
                </div>

                <button
                  type="submit"
                  onClick={() => navigate('/purchase/unlimited')}
                  className="w-full px-7 py-4 text-2xl rounded-md hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700 bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white"
                >
                  Get the Unlimited Plan Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <h3 className="text-4xl md:text-2xl font-extrabold text-center mb-2 mt-16 text-gray-900">Want MORE FREE <RiCoinsFill size={25} className='inline-block mt-[-5px]' />Credits?</h3>
        <p className="text-center text-2xl max-w-[80%] mx-auto my-4 text-gray-700">
          We'll give you 50 <RiCoinsFill size={25} className='inline-block mt-[-5px]' />credits for free for every feedback that you send us!
          <br />
          ImagesForAds.Ai is currently in BETA and we're working hard to improve! We need your feedback!
        </p>
        <p className="text-center">
          <button
              className="flex justify-center flex-row w-[400px] block bg-lime-600 py-3 rounded-md text-white font-normal focus:outline-none mx-auto text-center hover:bg-gray-700 transition-all duration-300 weight-600"
              type="button"
          >
              <RiCoinsFill size={25} className='' />&nbsp;<span className="inline-block mx-[5px] font-normal text-xl">Submit Feedback for Free Credits</span>
          </button>
        </p>
      </div>
    </section>
  );
}